import { Stack } from "@mui/material";
import LayerTitle from "./layerTitle/LayerTitle";
import ReturnPeriod from "./returnPeriod/ReturnPeriod";
import RiskScore from "./riskScore/RiskScore";
import Expand from "./expand/Expand";
import { AnalysisItem, LegendItem } from "helpers/models";

interface Props {
  legendItem: LegendItem;
  analysisItem: AnalysisItem;
}

export default function FathomAnalyze(props: Readonly<Props>) {
  return (
    <Stack spacing={2} className="mb-3">
      <LayerTitle {...props} />
      <ReturnPeriod {...props} />
      <RiskScore {...props} />
      <Expand {...props} />
    </Stack>
  );
}
