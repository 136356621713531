import { downloadClimatePdf, getCrsAnalysisResult } from "api/layer";
import { Button } from "@mui/material";
import CrsLegend from "components/crsLegend/CrsLegend";
import Input from "components/input/input";
import Modal from "components/modal/modal";
import Table from "components/table/table";
import Tab from "containers/layers/tab/tab";
import domtoimage from "dom-to-image";
import { AppAction, AppContext } from "helpers/context";
import { getLanguage } from "helpers/translations";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./climate-risk-modal.module.scss";

enum Tabs {
	SSP126 = "ssp126",
	SSP245 = "ssp245",
	SSP585 = "ssp585",
}

function ClimateRiskModal() {
	const [context, dispatch] = useContext(AppContext);

	const [tab, setTab] = useState<Tabs>(Tabs.SSP126);
	const [sSP126Arr, setSSP126Arr] = useState<any[]>([]);
	const [sSP245Arr, setSSP245Arr] = useState<any[]>([]);
	const [sSP585Arr, setSSP585Arr] = useState<any[]>([]);
	const [tableData, setTableData] = useState<any[]>([]);
	const [graunch, setGraunch] = useState<boolean>(false);
	const [downloadModal, setDownloadModal] = useState(false);
	const [isLoading, setIsLoading] = useState("0");
	const [downloadName, setDownloadName] = useState("");

	const [t] = useTranslation();

	let requestNumber = 0;

	const close = (): void => {
		dispatch({ type: AppAction.setClimateRiskModal, payload: false });
	};

	const getClimateRiskData = async () => {
		if (!context.climateRiskModal) {
			requestNumber = 0;
			return;
		}
		let batchId = context.batchId as string;
		const res: any = await getCrsAnalysisResult(batchId);
		requestNumber++;
		if (requestNumber >= 10) {
			setGraunch(true);
			return;
		}
		if (res.data.status !== "Completed") {
			setTimeout(() => {
				getClimateRiskData();
			}, 3000);
			return;
		}
		requestNumber = 0;

		let SSP126Arr: any = [],
			SSP245Arr: any = [],
			SSP585Arr: any = [];
		res.data.results[0].climateRiskScores.forEach((item: any) => {
			item.scenarios.forEach((scenario: any) => {
				let arr: any = [];
				arr.push(item.index);
				scenario.bandGroups[0].bands.forEach((band: any) => {
					if (band.value === "NaN") {
						arr.push("");
					} else {
						arr.push((band.value * 1).toFixed(2));
					}
				});
				if (scenario.name === "SSP1-2.6") {
					SSP126Arr.push(arr);
				} else if (scenario.name === "SSP2-4.5") {
					SSP245Arr.push(arr);
				} else if (scenario.name === "SSP5-8.5") {
					SSP585Arr.push(arr);
				}
			});
		});
		setSSP126Arr(SSP126Arr);
		setSSP245Arr(SSP245Arr);
		setSSP585Arr(SSP585Arr);
		setTableData(SSP126Arr);
	};

	useEffect(() => {
		if (context.climateRiskModal) {
			setGraunch(false);
			setTab(Tabs.SSP126);
			getClimateRiskData();
		}
	}, [context.climateRiskModal]);

	const download = async () => {
		setIsLoading("1");

		const layerIdArray = sSP126Arr.map(([layerId]) => layerId);
		const [A1, B1, C1, D1, E1, F1, G1] = layerIdArray;

		const SSP1_TITLE = t(`climateRiskModal.ssp126.title`);
		const SSP1_TEXT = t(`climateRiskModal.ssp126.info`);
		const SSP1_TABLE = sSP126Arr.map(([label, v2030, v2035, v2040, v2045, v2050, v2055, v2060, v2070, v2085]) => ({
			label: t(`layerId.${label}`),
			v2030,
			v2035,
			v2040,
			v2045,
			v2050,
			v2055,
			v2060,
			v2070,
			v2085,
		}));

		SSP1_TABLE.forEach((item: any) => {
			for (let key in item) {
				if (item[key] === "NaN") {
					item[key] = t("report.NaN");
				}
			}
		});

		const SSP2_TITLE = t(`climateRiskModal.ssp245.title`);
		const SSP2_TEXT = t(`climateRiskModal.ssp245.info`);
		const SSP2_TABLE = sSP245Arr.map(([label, v2030, v2035, v2040, v2045, v2050, v2055, v2060, v2070, v2085]) => ({
			label: t(`layerId.${label}`),
			v2030,
			v2035,
			v2040,
			v2045,
			v2050,
			v2055,
			v2060,
			v2070,
			v2085,
		}));

		SSP2_TABLE.forEach((item: any) => {
			for (let key in item) {
				if (item[key] === "NaN") {
					item[key] = t("report.NaN");
				}
			}
		});

		const SSP3_TITLE = t(`climateRiskModal.ssp585.title`);
		const SSP3_TEXT = t(`climateRiskModal.ssp585.info`);
		const SSP3_TABLE = sSP585Arr.map(([label, v2030, v2035, v2040, v2045, v2050, v2055, v2060, v2070, v2085]) => ({
			label: t(`layerId.${label}`),
			v2030,
			v2035,
			v2040,
			v2045,
			v2050,
			v2055,
			v2060,
			v2070,
			v2085,
		}));

		SSP3_TABLE.forEach((item: any) => {
			for (let key in item) {
				if (item[key] === "NaN") {
					item[key] = t("report.NaN");
				}
			}
		});

		const param: any = {
			// A1,
			// B1,
			// C1,
			// D1,
			// E1,
			// F1,
			// G1,
			// A2: t(`layerId.${A1}`),
			// B2: t(`layerId.${B1}`),
			// C2: t(`layerId.${C1}`),
			// D2: t(`layerId.${D1}`),
			// E2: t(`layerId.${E1}`),
			// F2: t(`layerId.${F1}`),
			// G2: t(`layerId.${G1}`),
			SSP1_TITLE,
			SSP1_TEXT,
			SSP1_TABLE,
			SSP2_TITLE,
			SSP2_TEXT,
			SSP2_TABLE,
			SSP3_TITLE,
			SSP3_TEXT,
			SSP3_TABLE,
		};

		if (A1) {
			param.A1 = A1;
			param.A2 = t(`layerId.${A1}`);
		}

		if (B1) {
			param.B1 = B1;
			param.B2 = t(`layerId.${B1}`);
		}

		if (C1) {
			param.C1 = C1;
			param.C2 = t(`layerId.${C1}`);
		}

		if (D1) {
			param.D1 = D1;
			param.D2 = t(`layerId.${D1}`);
		}

		if (E1) {
			param.E1 = E1;
			param.E2 = t(`layerId.${E1}`);
		}

		if (F1) {
			param.F1 = F1;
			param.F2 = t(`layerId.${F1}`);
		}

		if (G1) {
			param.G1 = G1;
			param.G2 = t(`layerId.${G1}`);
		}

		param["T2"] = downloadName;
		param["T3"] = t("report.date") + "：" + new Date().toLocaleString();
		param["T4"] = t("report.Climate Risk Score");
		param["T5"] = t("report.Risk Analyzed");

		// param["tem"] = "climate.docx";

		param["lang"] = getLanguage();

		if (getLanguage() === "en") {
			param["tem"] = "climate_en.docx";
		} else {
			param["tem"] = "climate_cn.docx";
		}

		try {
			param["map"] = await captureScreen(document.getElementsByClassName("amap-maps")[0]);
		} catch (error) {
			console.error("capture map error:", error);
		}

		downloadClimatePdf(param)
			.then((response: any) => {
				const url = window.URL.createObjectURL(new Blob([response as any], { type: "application/force-download;charset=utf-8" }));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", `${downloadName}.pdf`);
				document.body.appendChild(link);
				link.click();
				setIsLoading("2");
				link.onload = () => {
					document.body.removeChild(link);
					window.URL.revokeObjectURL(url);
				};
			})
			.catch((error) => {
				console.error("下载失败:", error);
				setIsLoading("0");
				setDownloadModal(false);
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "climateRiskModalDownload",
						titleIconColor: "yellow",
						titleIcon: "warning",
						resMessage: t("climateRiskModal.downloadError"),
					},
				});
			});
	};

	const captureScreen = async (dom: any) => {
		return await domtoimage.toPng(dom!);
	};

	return (
		<Modal
			header={t("climateRiskModal.title")}
			opened={context.climateRiskModal}
			onClose={close}
		>
			<div className={styles.risk}>
				<div className={styles.tabs}>
					<Tab
						text="SSP 1-2.6"
						active={tab === Tabs.SSP126}
						onClick={() => {
							setTab(Tabs.SSP126);
							setTableData(sSP126Arr);
						}}
					/>
					<Tab
						text="SSP 2-4.5"
						active={tab === Tabs.SSP245}
						onClick={() => {
							setTab(Tabs.SSP245);
							setTableData(sSP245Arr);
						}}
					/>
					<Tab
						text="SSP 5-8.5"
						active={tab === Tabs.SSP585}
						onClick={() => {
							setTab(Tabs.SSP585);
							setTableData(sSP585Arr);
						}}
					/>
				</div>
				<div className={styles.description}>{t("climateRiskModal.description")}</div>
				<div className={styles.legend}>
					{/* <img
						src={context.language === "cn" ? "/crs_legend_cn.png" : "/crs_legend_en.png"}
						alt="risk-legend"
					/> */}
					<CrsLegend />
				</div>
				<div className={styles.title}>{t(`climateRiskModal.${tab}.title`)}</div>
				<div className={styles.info}>{t(`climateRiskModal.${tab}.info`)}</div>

				<Table
					tableData={tableData}
					graunch={graunch}
				/>

				<div className={styles.button}>
					<Button
						size="small"
						variant="outlined"
						color="success"
						onClick={() => {
							setDownloadModal(true);
							setDownloadName("");
							setIsLoading("0");
						}}
					>
						{t("buttons.download")}
					</Button>
					&nbsp;&nbsp;
					<Button color="success" variant="contained" onClick={close}>{t("buttons.close")}</Button>
				</div>
			</div>
			<Modal
				header={isLoading === "0" ? t("single.downloadTitle") : undefined}
				opened={downloadModal}
				onClose={() => setDownloadModal(false)}
			>
				<div className={styles.analyse}>
					<div className="mt-5 mb-5">
						{isLoading === "0" && (
							<Input
								value={downloadName}
								isClose={true}
								placeholder={t("single.downloadPlaceholder")}
								onChange={(event) => setDownloadName(event.target.value)}
								onClose={() => setDownloadName("")}
							/>
						)}
						{isLoading === "1" && (
							<div className="text-center">
								<div className="loading loading-spinner loading-lg"></div>
								<div>{t("single.downloading")}</div>
							</div>
						)}
						{isLoading === "2" && (
							<div className="text-center">
								<div>{t("single.downloadCompletes")}</div>
							</div>
						)}
					</div>
					<div className="flex justify-between mt-5">
						<div>
							{isLoading === "0" && (
								<Button
									color="success"
									onClick={() => setDownloadName("")}
								>
									{t("buttons.reset")}
								</Button>
							)}
						</div>
						<div>
							<Button
								color="success"
								onClick={() => setDownloadModal(false)}
							>
								{t("buttons.close")}
							</Button>
							{isLoading === "0" && (
								<Button
									size="small"
									variant="outlined"
									color="success"
									disabled={!downloadName}
									onClick={download}
								>
									{t("buttons.download")}
								</Button>
							)}
						</div>
					</div>
				</div>
			</Modal>
		</Modal >
	);
}

export default ClimateRiskModal;
