import Modal from "components/modal/modal";

import Button from "components/button/button";
import { LayerInfoModal } from "helpers/constants";
import { AppAction, AppContext } from "helpers/context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./info-modal.module.scss";

function InfoModal() {
  const [context, dispatch] = useContext(AppContext);
  const [t] = useTranslation();

  const title = (): string => {
    if (context.layerInfoModal) {
      return context.layerInfoModal.name
    }
    return "";
  };

  const text = (): string => {
    if (context.layerInfoModal) {
      return context.layerInfoModal.description
    }
    return "";
  };

 

  const close = (): void => {
    dispatch({
      type: AppAction.setLayerInfoModal,
      payload: undefined,
    });
  };

  return (
    <Modal
      header={title()}
      opened={(context.layerInfoModal ? true : false)}
      onClose={close}
    >
      <div className={styles.info}>
        <div className={styles.text}>{text()}</div>
        <div className={styles.button}>
          <Button onClick={close}>{t("buttons.close")}</Button>
        </div>
      </div>
    </Modal>
  );
}

export default InfoModal;
