import { Button } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Collapse from "./collapse/Collapse";

interface Props {
  detail: Detail;
}

interface Detail {
  [key: string]: string;
}

function getMatchingTitle(returnPeriod: string) {
  if (returnPeriod === "5" || returnPeriod === "10" || returnPeriod === "20") {
    return "≤50 years";
  } else if (returnPeriod === "50") {
    return "≤50 years";
  } else if (returnPeriod === "100") {
    return "100 years";
  } else if (returnPeriod === "200") {
    return "200 years";
  } else if (returnPeriod === "500") {
    return "500 years";
  } else if (returnPeriod === "1000") {
    return "≥1000 years";
  } else {
    return "Outside";
  }
}

export default function Expand({ detail }: Readonly<Props>) {
  const [t] = useTranslation();

  const [isExpand, setIsExpand] = useState(false);

  const handleExpand = () => {
    setIsExpand(!isExpand);
  };

  console.log(detail);

  return (
    <div>
      {!isExpand
        ? <Button variant="text" onClick={handleExpand} color="success">
            {t("buttons.expand")}
          </Button>
        : <Collapse detail={detail} handleCollapse={handleExpand} />}
    </div>
  );
}
