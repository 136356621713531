import { useState, useContext, useEffect } from "react";
import { AppAction, AppContext, } from "helpers/context";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Menu, MenuItem, IconButton, Toolbar, Box, TextField, InputAdornment, } from "@mui/material";
import styles from "./login-statistics.module.scss";
import { getstatLoginCountData, downloadLoginStatistic } from "api/layer"
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

function LoginStatistics() {
	const { t } = useTranslation();
	const [context, dispatch] = useContext(AppContext);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [statisticsData, setStatisticsData] = useState<any[]>([]);
	const [totalStatistic, setTotalStatistic] = useState<any>({});
	const [totalCount, setTotalCount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [searchIpt, setSearchIpt] = useState("");
	const [searchData, setSearchData] = useState({
		keyword: ""
	});
	let currYear = dayjs().year();

	const hanldeDownload = () => {
		downloadLoginStatistic({ ...searchData, language: context.language }).then((res: any) => {
			const url = window.URL.createObjectURL(new Blob([res as any], { type: "application/force-download;charset=utf-8" }));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `${t("userSystem.loginStatisticDownload")}.xls`);
			document.body.appendChild(link);
			link.click();
			link.onload = () => {
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			};
		})
	}

	const handleSearch = (params: any) => {
		setSearchData((prevData) => ({ ...prevData, [params.name]: params.value }));
		setPage(0);
		handleGetLoginStatisticData({ pageNo: 1, search: { ...searchData, [params.name]: params.value } });
	}

	const handleKeyDown = (event: any) => {
		if (event.key === "Enter") {
			handleSearch({ name: "keyword", value: event.target.value })
		}
	};

	const toggleFilters = () => {
		setShowFilters(!showFilters);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
		handleGetLoginStatisticData({ pageNo: newPage + 1 });
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		handleGetLoginStatisticData({ pageNo: 0, pageSize: parseInt(event.target.value, 10) });
	};

	const handleGetLoginStatisticData = async ({ pageNo = page + 1, pageSize = rowsPerPage, search = searchData } = {}) => {
		setIsLoading(true);
		let params = { ...search };
		let res: any = await getstatLoginCountData({ pageNo, pageSize, ...params })
		setIsLoading(false)
		if (res.code !== 200) return
		setStatisticsData(res.data.rows)
		setTotalCount(res.data.totalCount * 1 || 0)
		setTotalStatistic(res.data.totalStatistic)
	}

	useEffect(() => {
		handleGetLoginStatisticData()
	}, [])

	return (
		<div className={styles.container}>
			<Toolbar style={{ justifyContent: "space-between", borderBottom: "1px solid #ccc", marginBottom: "5px", padding: "0" }}>
				<Button
					color="success"
					variant="outlined"
					size="small"
					onClick={hanldeDownload}
				>
					{t("buttons.download")}
				</Button>

				<IconButton
					color="success"
					onClick={toggleFilters}
					style={{ color: showFilters ? "#2e7d32" : "rgba(0, 0, 0, 0.54)" }}
				>
					<span className="material-icons">filter_list</span>
				</IconButton>
			</Toolbar>
			{showFilters && (
				<Box style={{ display: "flex", alignItems: "center", padding: "8px", backgroundColor: "#f5f5f5", justifyContent: "space-between" }}>
					<Box style={{ display: "flex", flex: 1, alignItems: "center" }}>
						<TextField
							color="success"
							variant="outlined"
							size="small"
							placeholder={t("userSystem.filterByKeyword")}
							value={searchIpt}
							onChange={(e) => setSearchIpt(e.target.value)}
							onKeyDown={handleKeyDown}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<span className="material-icons">filter_list</span>
									</InputAdornment>
								),
								endAdornment: (
									searchIpt && <InputAdornment position="end">
										<span className="material-icons cursor-pointer" onClick={() => {
											setSearchIpt("")
											searchData.keyword && handleSearch({ name: "keyword", value: "" })
										}}>close</span>
									</InputAdornment>
								)
							}}
							sx={{
								width: "250px",
								"& .MuiOutlinedInput-notchedOutline": {
									borderWidth: "0",
								},
								"& .MuiOutlinedInput-input,& .MuiOutlinedInput-input::placeholder": {
									fontSize: "14px",
								}
							}}
						/>
					</Box>
					<IconButton
						onClick={() => setShowFilters(false)}
						aria-label="clear"
						color="default"
					>
						<span className="material-icons">clear</span>
					</IconButton>
				</Box>
			)}

			<div style={{ flex: 1, overflow: "auto" }}>
				<Table
					stickyHeader
					aria-label="sticky  table"
					size="small">
					<TableHead>
						<TableRow>
							<TableCell style={{ minWidth: 100 }}>{t("userSystem.userAccount")}</TableCell>
							<TableCell style={{ minWidth: 130 }}>{t("userSystem.tenantName")}</TableCell>
							<TableCell style={{ minWidth: 100 }}>{t("userSystem.roleName")}</TableCell>
							<TableCell style={{ minWidth: 140 }}>{context.language === "en" ? ("Number of logins in " + (currYear - 1)) : ((currYear - 1) + "年登录次数")}</TableCell>
							<TableCell>{context.language === "en" ? "Number of logins in Q1 " + currYear : currYear + "第1季度登录次数"}</TableCell>
							<TableCell>{context.language === "en" ? "Number of logins in Q2 " + currYear : currYear + "第2季度登录次数"}</TableCell>
							<TableCell>{context.language === "en" ? "Number of logins in Q3 " + currYear : currYear + "第3季度登录次数"}</TableCell>
							<TableCell>{context.language === "en" ? "Number of logins in Q4 " + currYear : currYear + "第4季度登录次数"}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading ? (
							<TableRow>
								<TableCell colSpan={8}>
									<div className={styles.loading}>
										<div className="loading loading-spinner loading-lg"></div>
										<div>{t("layers.loading")}</div>
									</div>
								</TableCell>
							</TableRow>
						) : (<>
							<TableRow>
								<TableCell colSpan={3}>{t("multiple.total")}</TableCell>
								<TableCell>{totalStatistic.ly_count}</TableCell>
								<TableCell>{totalStatistic.q1_count}</TableCell>
								<TableCell>{totalStatistic.q2_count}</TableCell>
								<TableCell>{totalStatistic.q3_count}</TableCell>
								<TableCell>{totalStatistic.q4_count}</TableCell>
							</TableRow>
							{statisticsData.map((data, index) => (
								<TableRow key={index}>
									<TableCell>{data.user_account}</TableCell>
									<TableCell>{data.tenant_name}</TableCell>
									<TableCell>{data.roles ? data.roles.split(",").map((roleCode: any) => t("userSystem.roleSelect." + roleCode)).join("|") : ""}</TableCell>
									<TableCell>{data.ly_count}</TableCell>
									<TableCell>{data.q1_count}</TableCell>
									<TableCell>{data.q2_count}</TableCell>
									<TableCell>{data.q3_count}</TableCell>
									<TableCell>{data.q4_count}</TableCell>
								</TableRow>))}
						</>)}
					</TableBody>
				</Table>
			</div>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={totalCount}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				labelRowsPerPage={t("userSystem.rowsPerPage")}
			/>
		</div >
	);
}

export default LoginStatistics;
