import { useState, useEffect, useContext } from "react";
import {
	Button, Box, Table, TableBody, TableCell, TableContainer, TableHead,
	Checkbox, TableRow, TablePagination, Toolbar, IconButton, TextField, InputAdornment, TableSortLabel
} from "@mui/material";
import styles from "./user.module.scss";
import UserAddModal from "./user-add-modal/userAddModal";
import { useTranslation } from "react-i18next";
import { getUserData, addUserData, getTenantAllData, deleteUserData, editUserData, getRoleData, editBatchUserData, downloadUserData } from "api/layer";
import { statusBgColor, statusText } from "helpers/helpers";
import StatusDropdown from "./status-dropdown/status-dropdown";
import RoleDropdown from "./role-dropdown/role-dropdown";
import StartTimeDropdown from "./start-time-dropdown/start-time-dropdown";
import EndTimeDropdown from "./end-time-dropdown/end-time-dropdown";
import Modal from "components/modal/modal";
import { AppContext, AppAction } from "helpers/context";
import dayjs from "dayjs";

function User() {
	const [t] = useTranslation();
	const [context, dispatch] = useContext(AppContext);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [totalCount, setTotalCount] = useState(0);
	const [rowCount, setRowCount] = useState<any[]>([]);
	const [showFilters, setShowFilters] = useState(false);
	const [userData, setUserData] = useState([]);
	const [tenantData, setTenantData] = useState([]);
	const [roleData, setRoleData] = useState([]);
	const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [deleteModalData, setDeleteModalData] = useState(false);
	const [currentData, setCurrentData] = useState<any>(undefined);
	const [userIds, setUserIds] = useState<any>(undefined);
	const [searchIpt, setSearchIpt] = useState("");
	const [searchData, setSearchData] = useState<{
		roleIds: any[];
		statuses: string[];
		keyword: string;
		startTime: any;
		endTime: any;
	}>({
		roleIds: [],
		statuses: [],
		keyword: "",
		startTime: null,
		endTime: null,
	});

	const [sortByField, setSortByField] = useState<"user_code" | "user_account" | "tenant_name" | "role_names" | "status" | "start_time" | "end_time">("start_time");
	const [sortOrder, setSortOrder] = useState<{
		user_code: "asc" | "desc",
		user_account: "asc" | "desc",
		tenant_name: "asc" | "desc",
		role_names: "asc" | "desc",
		status: "asc" | "desc",
		start_time: "asc" | "desc",
		end_time: "asc" | "desc"
	}>({
		user_code: "asc",
		user_account: "asc",
		tenant_name: "asc",
		role_names: "asc",
		status: "asc",
		start_time: "desc",
		end_time: "asc",
	});

	const hanldeDownload = () => {
		const filteredData = Object.fromEntries(
			Object.entries(searchData).filter(([key, value]) => value !== null && value !== '')
		);
		downloadUserData({ ...filteredData, language: context.language }).then((res: any) => {
			console.log(res);
			const url = window.URL.createObjectURL(new Blob([res as any], { type: "application/force-download;charset=utf-8" }));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `${t("userSystem.user")}.xls`);
			document.body.appendChild(link);
			link.click();
			link.onload = () => {
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			};
		});
	};

	const handleSelectAllClick = (event: any) => {
		const checked = event.target.checked;
		setRowCount(checked ? userData.map((row: any) => row.id) : []);
	};

	const handleSearch = (params: any) => {
		setSearchData((prevData) => ({ ...prevData, [params.name]: params.value }));
		if ((params.name === "startTime" || params.name === "endTime") && params.value && params.value.format("YYYY-MM-DD") === "Invalid Date") {
			return;
		}
		setPage(0);
		handleGetUserData({ pageNo: 1 }, { ...searchData, [params.name]: params.value });
	};

	const handleKeyDown = (event: any) => {
		if (event.key === "Enter") {
			handleSearch({ name: "keyword", value: event.target.value });
		}
	};

	const handleSave = async (params: any) => {
		setOpen(false);
		let res: any = {};
		if (userIds) {
			res = await editBatchUserData(params);
			if (res.code === 200) {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "editUser",
						message: "editUserSuccess",
					},
				});
			} else {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "editUser",
						titleIconColor: "yellow",
						titleIcon: "warning",
						resMessage: res.message,
					},
				});
				return;
			}
		} else if (currentData) {
			res = await editUserData(params);
			if (res.code === 200) {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "editUser",
						message: "editUserSuccess",
					},
				});
			} else {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "editUser",
						titleIconColor: "yellow",
						titleIcon: "warning",
						resMessage: res.message,
					},
				});
				return;
			}
		} else {
			res = await addUserData(params);
			if (res.code === 200) {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "addUser",
						message: "addUserSuccess",
					},
				});
			} else {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "addUser",
						titleIconColor: "yellow",
						titleIcon: "warning",
						resMessage: res.message,
					},
				});
				return;
			}
		}
		handleGetUserData();
	};

	const toggleFilters = () => {
		setShowFilters(!showFilters);
	};

	const handleChangePage = (event: any, newPage: number) => {
		setRowCount([]);
		setPage(newPage);
		handleGetUserData({ pageNo: newPage + 1 });
	};

	const handleChangeRowsPerPage = (event: any) => {
		setRowCount([]);
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		handleGetUserData({ pageNo: 0, pageSize: parseInt(event.target.value, 10) });
	};

	const handleDeleteUserData = () => {
		const ids = currentData ? [currentData.id] : rowCount;
		deleteUserData(ids).then((res: any) => {
			if (res.code === 200) {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "deleteUser",
						message: "deleteUserSuccess",
					},
				});
			} else {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "deleteUser",
						titleIconColor: "yellow",
						titleIcon: "warning",
						resMessage: res.message,
					},
				});
				return;
			}
			handleChangePage(undefined, 0);
		});
		setCurrentData(undefined);
		setDeleteModalData(false);
	};

	const handleGetUserData = async ({
		pageNo = page + 1,
		pageSize = rowsPerPage,
		sortOrderAsc = sortOrder[sortByField] === "asc",
		sortByFields = sortByField
	} = {},
		data = searchData) => {
		setIsLoading(true);
		let params = { ...data };
		if (data.startTime) {
			params.startTime = data.startTime.format("YYYY-MM-DD");
		}
		if (data.endTime) {
			params.endTime = data.endTime.format("YYYY-MM-DD");
		}
		const res: any = await getUserData(params, { pageNo, pageSize, sortOrderAsc, sortByField: sortByFields });
		setIsLoading(false);
		if (res.code !== 200) return;
		setUserData(res.data.rows);
		setTotalCount(res.data.totalCount * 1 || 0);
	};

	const handleGetTenantAllData = async () => {
		const res: any = await getTenantAllData();
		if (res.code !== 200) return;
		setTenantData(res.data);
	};

	const handleGetRoleData = async () => {
		const res: any = await getRoleData();
		if (res.code !== 200) return;
		setRoleData(res.data);
	};

	useEffect(() => {
		handleGetUserData();
		handleGetTenantAllData();
		handleGetRoleData();
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.title}>{t("userSystem.user")}</div>
			<Toolbar style={{ justifyContent: "space-between", borderBottom: "1px solid #ccc", marginBottom: "5px", padding: "0" }}>
				<div>
					{context.menu && context.menu.find((item) => item.id === "100002") && (
						<Button
							variant="contained"
							color="success"
							size="small"
							endIcon={<span className="material-icons">add</span>}
							onClick={() => {
								setCurrentData(undefined);
								setUserIds(undefined);
								setOpen(true);
							}}
						>
							{t("userSystem.addUser")}
						</Button>
					)}
					<Button
						size="small"
						variant="outlined"
						color="success"
						style={{ padding: "4px 10px", margin: "0 10px" }}
						onClick={hanldeDownload}
					>
						{t("buttons.download")}
					</Button>
					{context.menu && context.menu.find((item) => item.id === "100002") && (
						<Button
							variant="outlined"
							size="small"
							color="success"
							style={{ padding: "4px 10px", marginRight: "10px" }}
							disabled={rowCount.length === 0}
							onClick={() => {
								setUserIds(rowCount);
								setOpen(true);
							}}
						>
							{t("buttons.edit")}
						</Button>
					)}

					{context.menu && context.menu.find((item) => item.id === "100002") && (
						<Button
							size="small"
							variant="text"
							color="error"
							style={{ padding: "4px 10px" }}
							disabled={rowCount.length === 0}
							onClick={() => {
								setCurrentData(undefined);
								setUserIds(undefined);
								setDeleteModalData(true);
							}}
						>
							{t("buttons.delete")}
						</Button>
					)}
				</div>
				<IconButton
					color="success"
					onClick={toggleFilters}
					style={{
						color: showFilters ? "#2e7d32" : "rgba(0, 0, 0, 0.54)",
						background: showFilters ? "#c1e5ca" : "",
					}}
				>
					<span className="material-icons">filter_list</span>
				</IconButton>
			</Toolbar>
			{showFilters && (
				<Box style={{ display: "flex", alignItems: "center", padding: "8px", backgroundColor: "#f5f5f5", justifyContent: "space-between" }}>
					<Box style={{ display: "flex", flex: 1, alignItems: "center" }}>
						<TextField
							color="success"
							variant="outlined"
							size="small"
							placeholder={t("userSystem.filterByKeyword")}
							value={searchIpt}
							onChange={(e) => setSearchIpt(e.target.value)}
							onKeyDown={handleKeyDown}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<span className="material-icons">filter_list</span>
									</InputAdornment>
								),
								endAdornment: searchIpt && (
									<InputAdornment position="end">
										<span
											className="material-icons cursor-pointer"
											onClick={() => {
												setSearchIpt("");
												searchData.keyword && handleSearch({ name: "keyword", value: "" });
											}}
										>
											close
										</span>
									</InputAdornment>
								),
							}}
							sx={{
								width: "250px",
								"& .MuiOutlinedInput-notchedOutline": {
									borderWidth: "0",
								},
								"& .MuiOutlinedInput-input,& .MuiOutlinedInput-input::placeholder": {
									fontSize: "14px",
								},
							}}
						/>
						<RoleDropdown
							roleData={roleData}
							selectedRoles={searchData.roleIds}
							onSave={(value) => handleSearch({ name: "roleIds", value })}
						/>
						<StatusDropdown
							selectedStatus={searchData.statuses}
							onSave={(value) => handleSearch({ name: "statuses", value })}
						/>
						{/* <StartTimeDropdown
							defaultTime={searchData.startTime}
							onSave={(value) => handleSearch({ name: "startTime", value })}
						/>
						<EndTimeDropdown
							defaultTime={searchData.endTime}
							onSave={(value) => handleSearch({ name: "endTime", value })}
						/> */}
					</Box>
					<IconButton
						onClick={() => setShowFilters(false)}
						aria-label="clear"
						color="default"
					>
						<span className="material-icons">clear</span>
					</IconButton>
				</Box>
			)}
			<div style={{ flex: 1, overflow: "auto" }}>
				<Table
					className={styles.table}
					stickyHeader
					aria-label="sticky  table"
					size="small"
				>
					<TableHead>
						<TableRow>
							<TableCell
								style={{ padding: 0, width: 50 }}
								align="center"
							>
								<Checkbox
									color="success"
									indeterminate={rowCount.length > 0 && rowsPerPage !== rowCount.length}
									checked={rowCount.length > 0 && rowsPerPage === rowCount.length}
									onChange={handleSelectAllClick}
									inputProps={{
										"aria-label": "select all desserts",
									}}
								/>
							</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortByField === "user_code"}
									onClick={() => {
										handleGetUserData({ sortOrderAsc: !(sortOrder.user_code === "asc"), sortByFields: "user_code" })
										setSortByField("user_code")
										let sort: any = {
											...sortOrder,
											user_code: sortOrder.user_code === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.user_code}
								>
									{t("userSystem.userCode")}
								</TableSortLabel>
							</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortByField === "user_account"}
									onClick={() => {
										handleGetUserData({ sortOrderAsc: !(sortOrder.user_account === "asc"), sortByFields: "user_account" })
										setSortByField("user_account")
										let sort: any = {
											...sortOrder,
											user_account: sortOrder.user_account === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.user_account}
								>
									{t("userSystem.userAccount")}
								</TableSortLabel>
							</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortByField === "tenant_name"}
									onClick={() => {
										handleGetUserData({ sortOrderAsc: !(sortOrder.tenant_name === "asc"), sortByFields: "tenant_name" })
										setSortByField("tenant_name")
										let sort: any = {
											...sortOrder,
											tenant_name: sortOrder.tenant_name === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.tenant_name}
								>
									{t("userSystem.owningTenant")}
								</TableSortLabel>
							</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortByField === "role_names"}
									onClick={() => {
										handleGetUserData({ sortOrderAsc: !(sortOrder.role_names === "asc"), sortByFields: "role_names" })
										setSortByField("role_names")
										let sort: any = {
											...sortOrder,
											role_names: sortOrder.role_names === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.role_names}
								>
									{t("userSystem.roleName")}
								</TableSortLabel>
							</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortByField === "status"}
									onClick={() => {
										handleGetUserData({ sortOrderAsc: !(sortOrder.status === "asc"), sortByFields: "status" })
										setSortByField("status")
										let sort: any = {
											...sortOrder,
											status: sortOrder.status === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.status}
								>
									{t("userSystem.status")}
								</TableSortLabel>
							</TableCell>
							<TableCell style={{ minWidth: 110 }}>
								<TableSortLabel
									active={sortByField === "start_time"}
									onClick={() => {
										handleGetUserData({ sortOrderAsc: !(sortOrder.start_time === "asc"), sortByFields: "start_time" })
										setSortByField("start_time")
										let sort: any = {
											...sortOrder,
											start_time: sortOrder.start_time === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.start_time}
								>
									{t("userSystem.startDate")}
								</TableSortLabel>
							</TableCell>
							<TableCell style={{ minWidth: 110 }}>
								<TableSortLabel
									active={sortByField === "end_time"}
									onClick={() => {
										handleGetUserData({ sortOrderAsc: !(sortOrder.end_time === "asc"), sortByFields: "end_time" })
										setSortByField("end_time")
										let sort: any = {
											...sortOrder,
											end_time: sortOrder.end_time === "asc" ? "desc" : "asc"
										}
										setSortOrder(sort)
									}}
									direction={sortOrder.end_time}
								>
									{t("userSystem.endDate")}
								</TableSortLabel>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading ? (
							<TableRow>
								<TableCell colSpan={8}>
									<div className={styles.loading}>
										<div className="loading loading-spinner loading-lg"></div>
										<div>{t("layers.loading")}</div>
									</div>
								</TableCell>
							</TableRow>
						) : (
							userData.map((user: any, index: number) => (
								<TableRow
									key={index}
									className={styles.row}
									sx={{
										minHeight: "48px",
									}}
								>
									<TableCell
										style={{ padding: 0, width: 50 }}
										align="center"
									>
										<Checkbox
											color="success"
											checked={rowCount.includes(user.id)}
											onChange={(event) => {
												if (event.target.checked) {
													setRowCount([...rowCount, user.id]);
												} else {
													setRowCount(rowCount.filter((id) => id !== user.id));
												}
											}}
											inputProps={{
												"aria-label": "select dessert",
											}}
										/>
									</TableCell>
									<TableCell>{user.userCode}</TableCell>
									<TableCell>{user.userAccount}</TableCell>
									<TableCell>{user.tenantName}</TableCell>
									<TableCell>{user.roleNames}</TableCell>
									<TableCell>
										<span
											className={styles.status}
											style={{ backgroundColor: statusBgColor(user.status) }}
										></span>
										{t("userSystem." + statusText(user.status))}
									</TableCell>
									<TableCell>{user.startTime}</TableCell>
									<TableCell>{user.endTime}</TableCell>
									{context.menu && context.menu.find((item) => item.id === "100002") && (
										<TableCell style={{ padding: 0 }}>
											<div className={styles.delete}>
												<div
													className={`${styles.icons} delete-set`}
													onClick={(evt) => evt.stopPropagation()}
												>
													<div
														className="tooltip tooltip-bottom"
														data-tip={t("tooltip.edit")}
													>
														<IconButton
															onClick={() => {
																setCurrentData(user);
																setOpen(true);
															}}
														>
															<span className="material-icons">edit</span>
														</IconButton>
													</div>
													<div
														className="tooltip tooltip-bottom"
														data-tip={t("tooltip.delete")}
													>
														<IconButton
															onClick={() => {
																setCurrentData(user);
																setDeleteModalData(true);
															}}
														>
															<span className="material-icons">delete</span>
														</IconButton>
													</div>
												</div>
											</div>
										</TableCell>
									)}
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</div>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={totalCount}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				labelRowsPerPage={t("userSystem.rowsPerPage")}
			/>

			{open && (
				<UserAddModal
					open={open}
					editData={currentData}
					tenantData={tenantData}
					userIds={userIds}
					onClose={() => {
						setOpen(false);
						setUserIds(undefined);
					}}
					onSave={handleSave}
				/>
			)}

			{deleteModalData && (
				<Modal
					header={t("userSystem.deleteTitle")}
					opened={deleteModalData}
					onClose={() => setDeleteModalData(false)}
				>
					<div className={styles.info}>
						<div className={styles.text}>{currentData ? `${t("userSystem.prefix")}${currentData.userAccount}${t("userSystem.suffix")}` : t("userSystem.deleteUserMessage")}</div>

						<div className="flex justify-end mt-2">
							<Button
								variant="text"
								color="success"
								onClick={() => setDeleteModalData(false)}
								style={{ outline: "none" }}
							>
								{t("buttons.cancel")}
							</Button>
							&nbsp;&nbsp;
							<Button
								variant="contained"
								color="error"
								onClick={() => handleDeleteUserData()}
							>
								{t("buttons.delete")}
							</Button>
						</div>
					</div>
				</Modal>
			)}
		</div>
	);
}

export default User;
