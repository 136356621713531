import AMapLoader from "@amap/amap-jsapi-loader";
import { RightPanel, zoomLevelMax, zoomLevelMin } from "helpers/constants";
import { AppContext } from "helpers/context";
import { colorByTiv } from "helpers/locations";
import { MapContext } from "helpers/map";
import { MapSize } from "helpers/models";
import { useContext, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { ReactComponent as MapPinSvg } from "../../assets/icon_map_pin.svg";
import styles from "./amap.module.scss";
import DistanceMeter from "./distance-meter/distance-meter";
import LayerSwitch from "./layer-switch/layer-switch";
import Marker from "./map-marker/map-marker";
import WorldZoom from "./world-zoom/world-zoom";
import Zooms from "./zooms/zooms";

const amapkey = (window as any).amapKey;

interface Props {
	size: string;
}

function AMap(props: Props) {
	const [context] = useContext(AppContext);
	const { AMap, setAMap, map, setMap, mouseTool, setMouseTool, setPlaceSearch } = useContext(MapContext);
	const [t] = useTranslation();

	const mapClass = () => {
		switch (props.size) {
			case MapSize.XS:
				return styles.mapXS;
			case MapSize.S:
				return styles.mapS;
			case MapSize.M:
				return styles.mapM;
			case MapSize.L:
				return styles.mapL;
			default:
				return styles.mapL;
		}
	};

	function svgToBase64(svgElement: any) {
		return "data:image/svg+xml;charset=utf-8," + encodeURIComponent(ReactDOMServer.renderToString(svgElement));
	}

	useEffect(() => {
		(window as any)._AMapSecurityConfig = {
			securityJsCode: "1ff8751ba0f4086ec444b1acbb56f14a",
		};
		AMapLoader.load({
			key: amapkey, // 申请好的Web端开发者Key，首次调用 load 时必填
			version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
			plugins: ["AMap.Scale", "AMap.HawkEye", "AMap.ToolBar", "AMap.ControlBar", "AMap.MapType", "AMap.MouseTool", "AMap.PlaceSearch"], //需要使用的的插件列表，如比例尺'AMap.Scale'，支持添加多个如：['...','...']
		})
			.then((AMap) => {
				setAMap(AMap);
				var scale = new AMap.Scale({
					position: {
						bottom: "10px",
						right: "160px",
					},
				}),
					toolBar = new AMap.ToolBar({
						position: {
							bottom: "40px",
							right: "160px",
						},
					}),
					controlBar = new AMap.ControlBar(),
					overView = new AMap.HawkEye({
						opened: false,
					}),
					mapType = new AMap.MapType();

				const mapInstance = new AMap.Map("container", {
					// 设置地图容器id
					viewMode: "3D", // 是否为3D地图模式
					zoom: context.mapZoom, // 初始化地图级别
					center: context.mapCenter, // 初始化地图中心点位置
					resizeEnable: true,
					vectorMapForeign: "style_en",
					WebGLParams: {
						preserveDrawingBuffer: true,
					},
					zooms: [zoomLevelMin, zoomLevelMax],
					mapStyle: "amap://styles/normal",
				}),
					placeSearch = new AMap.PlaceSearch();
				setPlaceSearch(placeSearch);
				mapInstance.addControl(scale);
				// mapInstance.addControl(toolBar);
				// mapInstance.addControl(controlBar);
				mapInstance.addControl(overView);
				// mapInstance.addControl(mapType)
				let mouseTool = new AMap.MouseTool(mapInstance);
				setMouseTool(mouseTool);
				setMap(mapInstance);
			})
			.catch((e) => {
				console.log(e);
			});

		return () => {
			map?.destroy();
		};
	}, []);

	return (
		<div className="flex items-center">
			<div
				className={mapClass()}
				style={{ position: "relative" }}
			>
				<div
					id="container"
					style={{ height: "100%" }}
				></div>
				{/* <MapInfo></MapInfo> */}
				<DistanceMeter />
				{/* 截图复制功能暂时不部署 */}
				{/* <ScreenShot></ScreenShot> */}
				<LayerSwitch></LayerSwitch>
				<WorldZoom></WorldZoom>
				<Zooms></Zooms>

				{context.singleLocation?.address && context.searchMapCenter && context.rightPanel !== RightPanel.Empty && (
					<Marker
						position={context.searchMapCenter}
						isLabel={true}
						isLocation={true}
					/>
				)}

				{context.currentMultipleLocations &&
					context
						.currentMultipleLocations!.items?.filter((location) => location.checked)
						.map((location, i) => (
							<Marker
								key={i}
								position={[location.longitude, location.latitude]}
								locationData={location}
								imageUrl="/icon_map_pin_green.svg"
							// imageUrl={svgToBase64(
							// 	<MapPinSvg
							// 		className="w-[36px]"
							// 		// style={{ fill: colorByTiv(location.tiv) }}
							// 		style={{ fill: "green" }}
							// 	/>
							// )}
							/>
						))}
			</div>
		</div>
	);
}

export default AMap;
