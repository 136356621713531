import Button from "@mui/material/Button";
import Modal from "components/modal/modal";
import { AppAction, AppContext } from "helpers/context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./delete-location-set.module.scss";
import { deleteLocationsets } from "api/layer"
import { LeftPanel, RightPanel } from "helpers/constants";

function DeleteLocationSetModal() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();

	const close = (): void => {
		dispatch({
			type: AppAction.setDeleteLocationSetModal,
			payload: undefined,
		});
	};

	const deleteLocation = (): void => {
		if (!context.deleteLocationSetModal) {
			return;
		}

		deleteLocationsets(context.deleteLocationSetModal.id).then(() => {
			if (context.deleteLocationSetModal.id === context.currentMultipleLocations?.id) {
				dispatch({ type: AppAction.setCurrentMultipleLocations, payload: undefined });
				if (context.rightPanel === RightPanel.Multiple) {
					dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Empty });
				}
			}
			let multipleLocations = context.multipleLocations?.filter((item: any) => item.id !== context.deleteLocationSetModal.id);
			dispatch({ type: AppAction.setMultipleLocations, payload: multipleLocations });
			dispatch({ type: AppAction.setMultipleLocationsEdit, payload: false });
			dispatch({ type: AppAction.setLeftPanel, payload: LeftPanel.Locations }); dispatch({
				type: AppAction.setSnackBarParams, payload: {
					title: "deleteLocationSetTitle",
					text: context.deleteLocationSetModal.name,
					message1: "deleteLocationSetMessage1",
					message2: "deleteLocationSetMessage2",
				}
			})
		});

		close();
	};

	return (
		context.deleteLocationSetModal && (
			<Modal
				header={t("deleteSet.title")}
				opened={context.deleteLocationSetModal}
				onClose={close}
			>
				<div className={styles.info}>
					<div className={styles.text}>{`${t("deleteSet.prefix")}${context.deleteLocationSetModal.name}${t("deleteSet.suffix")}`}</div>

					<div className={styles.button}>
						<Button
							variant="text"
							color="success"
							onClick={close}
							style={{ outline: "none" }}
						>
							{t("buttons.cancel")}
						</Button>
						&nbsp;&nbsp;
						<Button
							variant="contained"
							color="error"
							onClick={deleteLocation}
						>
							{t("buttons.delete")}
						</Button>
					</div>
				</div>
			</Modal>
		)
	);
}

export default DeleteLocationSetModal;
