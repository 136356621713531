import * as React from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "./LayerInfo.module.scss";

interface Props {
  open: boolean;
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function LayerInfo({ open, onClose }: Readonly<Props>) {
  const [t] = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={styles.title}>
        {t("fathom.Risk Score")}
      </DialogTitle>

      <DialogContent>
        <DialogContentText className={styles.content}>
          {t("fathom.Relative Risk.The")}
          <span>
            {t("fathom.Relative Risk.Score")}
          </span>

          {t("fathom.Relative Risk.Text")}
        </DialogContentText>

        <div className={styles.content} style={{ marginTop: "10px" }}>
          {t("fathom.Relative Risk.The")}
          <span>
            {t("fathom.Risk Category.name")}
          </span>

          {t("fathom.Risk Category.calculated")}
        </div>

        <div className={styles.content}>
          <ul className={styles.list}>
            <li>
              <strong>
                {t("fathom.Risk Category 1 - 5.prefix")}
              </strong>
              {t("fathom.Risk Category 1 - 5.suffix")}
            </li>

            <li>
              <strong>
                {t("fathom.Risk Category 6 - 100.prefix")}
              </strong>
              {t("fathom.Risk Category 6 - 100.suffix")}
            </li>
          </ul>
        </div>
      </DialogContent>
      
      <DialogActions>
        <Button
          color="success"
          onClick={event => onClose(event)}
          style={{ margin: "0 10px 10px 0" }}
        >
          {t("buttons.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
