import styles from "./fathomIcon.module.scss";
import { ReactComponent as IconPremium } from "assets/icon_premium.svg";
import { ReactComponent as IconPremium1 } from "assets/icon_premium1.svg";
import { Tooltip, Dialog, Button } from '@mui/material';
import { AppAction, AppContext } from "helpers/context";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as BasicSvg } from "assets/basic.svg";
import { ReactComponent as PremiumSvg } from "assets/premium.svg";
import { ReactComponent as LayersSvg } from "assets/icon_layers.svg";
import { ReactComponent as EventsSvg } from "assets/icon_events.svg";

interface Props {
    style?: React.CSSProperties;
    className?: string;
}

function FathomIcon(props: Props) {

    const [context, dispatch] = useContext(AppContext);
    const [t] = useTranslation();
    const [open, setOpen] = useState(false);

    const handleClick = (event: any) => {
        event.preventDefault();
        // setOpen(true);
    }

    const handleCloseDialog = () => {
        setOpen(false);
    }

    return (<>
        <Tooltip title={t("layers.fathomFeeReminder")} placement="top">
            <IconPremium style={props.style} className={props.className} onClick={handleClick} />
        </Tooltip>

        <Dialog
            open={open}
            onClose={handleCloseDialog}
            sx={{
                "& .MuiDialog-paper": {
                    width: "839px",
                    maxWidth: "80vw",
                    maxHeight: "85vh",
                    margin: "0"
                }
            }}
        >
            <div className="flex flex-col overflow-hidden">
                <div className={styles.dialogHeader}>
                    {context.userRoles && <div
                        className="flex items-center justify-center rounded-[27px] text-white text-[12px] py-[4px] px-[8px] gap-[4px]"
                        style={{
                            background: "var(--premium-gradient-2, linear-gradient(180deg, #fbbb18 0%, #b77718 100%))",
                        }}
                    >
                        <IconPremium1 style={{ width: "24px", height: "24px" }} />
                        <span style={{ fontWeight: 500 }}>Premium+</span>
                    </div>}
                    <div className={styles.dialogTitle}>CatNet® Premium+ Modules</div>
                    <div className={styles.dialogSubtitle}>Even more data, adding deeper insights and expanding to new dimensions.</div>
                </div>
                <div className={styles.dialogContent}>
                    <div className={styles.dialogArticle}>
                        <span className={styles.dialogIcon}>
                            <LayersSvg className="w-[20px] h-[20px] fill-[#767676]" />
                        </span>
                        <article>
                            <h6>Fathom Flood Zones for the US (to be expanded globally throughout 2024!)</h6>
                            <p>This hazard layer represents a map of Return Periods at a 10-meter resolution for the entire US, Puerto Rico, Hawaii and parts of Alaska – across all major flood perils (fluvial, pluvial, coastal), with data provided by Fathom. Additional return periods and flood depths in meters for undefended, defended, and known defended scenarios are available via the Analyze Location(s) functionality in CatNet®.</p>
                        </article>
                    </div>
                    <div className={styles.divider}></div>
                    <div className={styles.dialogArticle}>
                        <span className={styles.dialogIcon}>
                            <EventsSvg className="w-[20px] h-[20px] fill-[#767676]" />
                        </span>
                        <article>
                            <h6 >Event footprints</h6>
                            <p>
                                <div> Including near real-time and selected historical event footprints for selected perils,such as Flood, Storm, Earthquake, Rainfall </div>
                            </p>
                        </article>
                    </div>
                    <div className={styles.divider}></div>
                </div>
                <div className={styles.dialogFoot}>
                    <Button
                        color="success"
                        onClick={handleCloseDialog}
                    >
                        {t(`buttons.close`)}
                    </Button>
                </div>
            </div>
        </Dialog>
    </>);
}

export default FathomIcon;