import Button from "@mui/material/Button";
import Modal from "components/modal/modal";
import { AppAction, AppContext } from "helpers/context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./delete-location.module.scss";
import { deleteLocationsetsItems } from "api/layer";
import { LocationData } from "helpers/models";

function DeleteLocationModal() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();

	const close = (): void => {
		dispatch({
			type: AppAction.setDeleteLocationModal,
			payload: undefined,
		});
	};

	const deleteLocation = (): void => {
		if (!context.deleteLocationModal) {
			return;
		}

		deleteLocationsetsItems(context.currentMultipleLocations!.id, context.deleteLocationModal.id).then(async (result) => {
			let currentMultipleLocations: any = context.currentMultipleLocations;
			currentMultipleLocations.items = currentMultipleLocations.items?.filter((location: LocationData) => location.id !== context.deleteLocationModal.id)
			let multipleLocations = context.multipleLocations?.map((locationSet: any) => {
				if (locationSet.id === currentMultipleLocations.id) {
					locationSet.items = currentMultipleLocations.items;
					locationSet.itemCount = currentMultipleLocations.items?.length || 0;
				}
				return locationSet;
			});
			
			
			dispatch({
				type: AppAction.setMultipleLocations,
				payload: multipleLocations,
			});
			dispatch({
				type: AppAction.setCurrentMultipleLocations,
				payload: currentMultipleLocations,
			});
			dispatch({
				type: AppAction.setSnackBarParams, payload: {
					title1: "deleteLocationTitle1",
					title2: "deleteLocationTitle2",
					text: context.deleteLocationModal.name,
				}
			})

			// if (currentMultipleLocations!.items?.length === 0) {
			// 	dispatch({ type: AppAction.setMultipleLocationsEdit, payload: false });
			// }
		});

		close();
	};

	return (
		context.deleteLocationModal && (
			<Modal
				header={t("deleteLocation.title")}
				opened={context.deleteLocationModal}
				onClose={close}
			>
				<div className={styles.info}>
					<div className={styles.text}>{`${t("deleteLocation.prefix")}${context.deleteLocationModal.name}${t("deleteLocation.suffix")}`}</div>

					<div className={styles.button}>
						<Button
							variant="text"
							color="success"
							onClick={close}
							style={{ outline: "none" }}
						>
							{t("buttons.cancel")}
						</Button>
						&nbsp;&nbsp;
						<Button
							variant="contained"
							color="error"
							onClick={deleteLocation}
						>
							{t("buttons.delete")}
						</Button>
					</div>
				</div>
			</Modal>
		)
	);
}

export default DeleteLocationModal;
