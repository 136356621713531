import {
	Button, Dialog, DialogActions, DialogContent, InputAdornment,
	DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack, TextField, FormControlLabel, Checkbox
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/en"; // 导入中文语言包
import "dayjs/locale/zh"; // 导入中文语言包
import { AppContext } from "helpers/context";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getRoleDataByTenant } from "api/layer"
import { ReactComponent as AdminSvg } from "assets/admin.svg";

interface Props {
	open: boolean;
	tenantData: any[];
	onClose: () => void;
	onSave: (data: FormData) => void;
	editData?: any;
	userIds?: string[];
}

interface FormData {
	userCode?: string;
	userAccount?: string;
	tenantId: string;
	roleIds: string[];
	status?: Boolean;
	startTime: any;
	endTime: any;
	id?: string;
}

function TenantAddModal(props: Props) {
	const [t] = useTranslation();
	const [context, dispatch] = useContext(AppContext);
	const [formData, setFormData] = useState<FormData>(() => {
		if (props.userIds) {
			return {
				userIds: props.userIds,
				tenantId: "",
				roleIds: [],
				startTime: null,
				endTime: null,
				status: false,
			}
		} else if (props.editData) {
			return {
				id: props.editData.id,
				userCode: props.editData.userCode,
				userAccount: props.editData.userAccount,
				tenantId: props.editData.tenantId,
				roleIds: [],
				status: (props.editData.status === "3"),
				startTime: dayjs(props.editData.startTime),
				endTime: dayjs(props.editData.endTime),
			}
		} else {
			return {
				userCode: "",
				userAccount: "",
				tenantId: "",
				roleIds: [],
				startTime: null,
				endTime: null,
			}
		}
	});
	const [isError, setIsError] = useState(false);
	const [roleOptions, setRoleOptions] = useState<any[]>([]);

	const handleChange = (event: any) => {
		const { name, value } = event.target;
		if (name === "tenantId" && value) {
			try {
				getRoleDataByTenant({ tenantId: value }).then((res: any) => {
					setRoleOptions(res.data || []);
				});
			}
			catch (error) {
				setRoleOptions([])
			}
			setFormData((prevData) => ({ ...prevData, [name]: value, roleIds: [] }));
		} else if (name === "roleIds") {
			setFormData((prevData) => ({ ...prevData, [name]: value === 'string' ? value.split(',') : value }));
		} else if (name === "status") {
			setFormData((prevData) => ({ ...prevData, [name]: !formData.status }));
		} else {
			setFormData((prevData) => ({ ...prevData, [name]: value }));
		}
	};

	const close = () => {
		props.onClose();
	};

	const handleSave = () => {
		let data: any = { ...formData };
		if ((!props.userIds && !data.userAccount) || !data.tenantId || !data.roleIds || !data.startTime || !data.endTime) {
			setIsError(true);
			return;
		} else {
			setIsError(false);
		}
		let min = minDate();
		let max = maxDate();
		data.startTime = formData.startTime.format("YYYY-MM-DD");
		data.endTime = formData.endTime.format("YYYY-MM-DD");
		if (data.startTime < min!.format("YYYY-MM-DD") || data.startTime > max!.format("YYYY-MM-DD") || data.endTime < min!.format("YYYY-MM-DD") || data.endTime > max!.format("YYYY-MM-DD") || data.startTime > data.endTime) {
			return
		}
		data.status = formData.status ? "3" : undefined;
		data.roleIds = data.roleIds.map((item: any) => item.id);
		props.onSave(data);
	};

	const minDate = () => {
		if (formData.tenantId) {
			return dayjs(props.tenantData.find((item) => item.id === formData.tenantId)?.startTime);
		}
	}

	const maxDate = () => {
		if (formData.tenantId) {
			return dayjs(props.tenantData.find((item) => item.id === formData.tenantId)?.endTime);
		}
	}

	useEffect(() => {
		if (props.editData) {
			getRoleDataByTenant({ tenantId: props.editData.tenantId }).then((res: any) => {
				setRoleOptions(res.data);
				let arr = res.data.filter((item: any) => props.editData.roleCodes.indexOf(item.roleCode) > -1)
				setFormData((prevData) => ({ ...prevData, roleIds: arr }));
			});
		}
	}, [props.editData])

	useEffect(() => {
		//// 因为mui的日期组件Previous month和Previous month无法进行中英翻译 删除上月和下月切换按钮的title属性
		const observer = new MutationObserver((mutationsList) => {
			mutationsList.forEach((mutation) => {
				if (mutation.type === "childList") {
					const buttons = document.querySelectorAll(".MuiPickersArrowSwitcher-root button");
					buttons.forEach((button) => {
						button.removeAttribute("title");
					});
				}
			});
		});

		observer.observe(document.body, { childList: true, subtree: true });

		return () => {
			observer.disconnect();
		};
	}, []);

	return (
		<>
			<Dialog
				open={props.open}
				onClose={close}
			>
				<DialogTitle
					sx={{
						fontWeight: 600,
						fontSize: "16px",
						opacity: 0.79,
						color: "#333",
						width: "400px",
					}}
				>
					{(props.editData || props.userIds) ? t("userSystem.editUser") : t("userSystem.addUser")}
				</DialogTitle>
				<DialogContent sx={{ paddingTop: "10px!important" }}>
					<Stack spacing={{ xy: 1, sm: 2 }}>
						{!props.userIds && <TextField
							name="userCode"
							value={formData.userCode}
							onChange={handleChange}
							label={t("userSystem.userCode")}
							variant="outlined"
							color="success"
						/>}

						{props.userIds && <TextField
							required
							name="userAccount"
							onChange={handleChange}
							disabled
							variant="outlined"
							placeholder={t("userSystem.userCheckNumber1") + props.userIds.length + t("userSystem.userCheckNumber2")}
							InputProps={{
								startAdornment: (<InputAdornment position="start"><AdminSvg className="fill-[green]" /></InputAdornment>)
							}}
							sx={{
								"& .Mui-disabled": {
									background: "rgba(240, 240, 240, 0.5)",
									"-webkit-text-fill-color": "black !important"
								}
							}}
						/>}
						{!props.userIds && <TextField
							required
							name="userAccount"
							value={formData.userAccount}
							error={isError && !formData.userAccount}
							onChange={handleChange}
							label={t("userSystem.userAccount")}
							variant="outlined"
							color="success"
						/>}

						<FormControl fullWidth error={isError && !formData.tenantId}>
							<InputLabel color="success" required>{t("userSystem.owningTenant")}</InputLabel>
							<Select
								name="tenantId"
								value={formData.tenantId}
								onChange={handleChange}
								label={t("userSystem.owningTenant")}
								color="success"
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: 350,
										},
									},
								}}
							>
								{props.tenantData.map((item) => (<MenuItem value={item.id} key={item.id}>{item.tenantName}</MenuItem>))}
							</Select>
						</FormControl>

						{formData.tenantId && <FormControl fullWidth error={isError && formData.roleIds.length === 0}>
							<InputLabel color="success" required>{t("userSystem.roleName")}</InputLabel>
							<Select
								multiple
								name="roleIds"
								value={formData.roleIds}
								onChange={handleChange}
								label={t("userSystem.roleName")}
								renderValue={(selected) => selected.map((item: any) => item.roleName).join(', ')}
								color="success"
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: 350,
										},
									},
								}}
							>
								{roleOptions.map((item) => (<MenuItem value={item} key={item.id}>{item.roleName}</MenuItem>))}
							</Select>
						</FormControl>}

						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale={context.language === "cn" ? "zh" : "en"}

						>
							<DatePicker
								label={t("userSystem.startDate") + " *"}
								value={formData.startTime}
								format="YYYY-MM-DD"
								onChange={(event) => {
									handleChange({ target: { name: "startTime", value: event } });
								}}
								minDate={minDate()}
								maxDate={maxDate()}
								sx={{
									"& .MuiInputLabel-root": {
										color: isError && !formData.startTime ? "#d32f2f" : "",
									},
									"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
										borderColor: isError && !formData.startTime ? "#d32f2f" : "",
									},
									"& .MuiInputLabel-root.Mui-focused": {
										color: isError && !formData.startTime ? "#d32f2f" : "green",
									},
									"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
										borderColor: isError && !formData.startTime ? "#d32f2f" : "green",
									},
								}}
							/>
							<DatePicker
								label={t("userSystem.endDate") + " *"}
								value={formData.endTime}
								format="YYYY-MM-DD"
								onChange={(event) => {
									handleChange({ target: { name: "endTime", value: event } });
								}}
								minDate={formData.startTime}
								maxDate={maxDate()}
								sx={{
									"& .MuiInputLabel-root": {
										color: isError && !formData.endTime ? "#d32f2f" : "",
									},
									"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
										borderColor: isError && !formData.endTime ? "#d32f2f" : "",
									},
									"& .MuiInputLabel-root.Mui-focused": {
										color: isError && !formData.endTime ? "#d32f2f" : "green",
									},
									"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
										borderColor: isError && !formData.endTime ? "#d32f2f" : "green",
									},
								}}
							/>
						</LocalizationProvider>

						{(props.userIds || props.editData) && (<FormControlLabel
							label={t('userSystem.editStatus')}
							control={<Checkbox checked={!!formData.status} onChange={handleChange} name="status" color="success" />}
						/>)}
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={close}
						color="success"
					>
						{t("buttons.cancel")}
					</Button>
					<Button
						onClick={handleSave}
						color="success"
						variant="contained"
					>
						{t("buttons.save")}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default TenantAddModal;
