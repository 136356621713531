import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { RightPanel } from "helpers/constants";
import DialogContentText from "@mui/material/DialogContentText";
import { getLanguage } from "helpers/translations";
import DialogTitle from "@mui/material/DialogTitle";
import { AppAction, AppContext } from "helpers/context";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./fathom-popup.module.scss";
import { getStartAnalysis, startMultipleAnalysis } from "api/layer";
import FathomIcon from "components/fathomIcon/fathomIcon";
import Svg from "components/svg/svg";
import Checkbox from "@mui/material/Checkbox";

interface climateRiskScores {
  index: string;
  scenario?: string;
}

interface location {
  id: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
}

interface AnalysisProps {
  catNetLayers: undefined | string[];
  responseStructure: undefined | string[];
  climateRiskScores: undefined | climateRiskScores[];
  longitude?: number | string;
  latitude?: number | string;
  country?: string;
  oversea?: boolean;
  spatial?: string;
  locations?: location[];
}

export default function FathomPopup() {
  const [context, dispatch] = useContext(AppContext);
  const [t] = useTranslation();
  const [donotAskAgain, setDonotAskAgain] = useState(false);

  const onClose = () => {
    dispatch({ type: AppAction.setFathomPopup, payload: false });
  };

  const selectedButtons = context.selectLayer;

  const FATHOM_LAYER_ARRAY = [
    "FL_Fluvial_Global_Fathom",
    "FL_Pluvial_Global_Fathom",
    "FL_Coastal_Global_Fathom"
  ];

  const runAnalysis = async () => {
    dispatch({ type: AppAction.setAskAgain, payload: donotAskAgain });
    if (!selectedButtons || !selectedButtons.length) {
      return;
    }
    if (context.rightPanel === RightPanel.Single) {
      let params: AnalysisProps = {
        catNetLayers: [],
        responseStructure: [],
        climateRiskScores: [],
        longitude: undefined,
        latitude: undefined,
        country: undefined,
        oversea: undefined,
        spatial: undefined
      };
      if (context.currentMultipleLocationInfo) {
        params.longitude = context.currentMultipleLocationInfo.longitudeWgs;
        params.latitude = context.currentMultipleLocationInfo.latitudeWgs;
        params.country = context.currentMultipleLocationInfo.country;
        params.spatial = "wgs";
      } else {
        params.longitude = context.singleLocation!.lon;
        params.latitude = context.singleLocation!.lat;
        params.country = context.singleLocation!.country;
        params.oversea = context.singleLocation!.oversea;
        params.spatial = context.singleLocation!.spatial;
      }

      if (selectedButtons[0].length > 0) {
        params.catNetLayers = selectedButtons[0];
        dispatch({
          type: AppAction.setAnalysisData,
          payload: { legendItems: "running", analysisItems: "running" }
        });
      }

      if (selectedButtons[1].length > 0) {
        params.climateRiskScores = selectedButtons[1].map((item: any) => {
          return { index: item };
        });
        params.responseStructure = [
          "{2030,2035,2040,2045,2050,2055,2060,2070,2085}"
        ];
        dispatch({ type: AppAction.setBatchId, payload: "running" });
      }

      getStartAnalysis(params).then((resp: any) => {
        if (resp.code && resp.code === 400) {
          dispatch({
            type: AppAction.setSnackBarParams,
            payload: {
              title: "tip",
              titleIconColor: "red",
              titleIcon: "error",
              resMessage:
                getLanguage() === "en"
                  ? resp.data.messageEN
                  : resp.data.messageCN
            }
          });

          dispatch({
            type: AppAction.setSingleLocationAnalysed,
            payload: false
          });
          dispatch({
            type: AppAction.setMultipleLocationsAnalysed,
            payload: false
          });
          return;
        }

        if (selectedButtons[0].length > 0) {
          const analysisItems =
            resp && resp.data && resp.data.catnet && resp.data.catnet.results;
          const sortArray = [
            "FL_Fluvial_Official",
            "FL_Fluvial_SwissRe",
            "FL_Surge_SwissRe",
            "FL_Pluvial_SwissRe",
            "EQ_LocalSoilCondition_Global_SwissRe",
            "EQ_Bedrock_Global_SwissRe",
            "EQ_Tsunami_SwissRe",
            "WS_Windspeed_Global_SwissRe",
            "CS_Hail_Global_SwissRe",
            "CS_Tornado_Global_SwissRe",
            "EQ_Landslide_Global_SwissRe",
            "WF_Wildfire_Global_SwissRe",
            "CS_Lightning_Global_SwissRe",
            "VO_AshThickness_Global_SwissRe",
            "WF_DistToBush_AUS_SwissRe",
            "DR_Subsidence_France_SwissRe"
          ];

          analysisItems &&
            analysisItems.length &&
            analysisItems.sort((a: any, b: any) => {
              const indexA = sortArray.indexOf(a.layerId);
              const indexB = sortArray.indexOf(b.layerId);
              if (indexA === -1 || indexB === -1) {
                return 0;
              }
              return indexA - indexB;
            });

          let legends =
            context.hazardLegends &&
            context.hazardLegends.filter((item) => {
              return selectedButtons[0].includes(item.layerId);
            });

          const analysisData = { legendItems: legends, analysisItems };
          dispatch({ type: AppAction.setAnalysisData, payload: analysisData });
        }

        if (selectedButtons[1].length > 0) {
          dispatch({
            type: AppAction.setBatchId,
            payload: resp && resp.data && resp.data.crs && resp.data.crs.batchId
          });
        }
      });

      dispatch({ type: AppAction.setSingleLocationAnalysed, payload: true });
      dispatch({
        type: AppAction.setMultipleLocationsAnalysed,
        payload: false
      });

      onClose();
    } else if (context.rightPanel === RightPanel.Multiple) {
      if (
        context.multipleLocationsSetId &&
        context.currentMultipleLocations &&
        context.multipleLocationsSetId !== context.currentMultipleLocations.id
      ) {
        dispatch({
          type: AppAction.setHazardMultipleAnalysedLegends,
          payload: undefined
        });
        dispatch({
          type: AppAction.setHazardMultipleAnalysisResult,
          payload: undefined
        });
        dispatch({
          type: AppAction.setHazardMultipleAnalysisStatus,
          payload: undefined
        });
        dispatch({
          type: AppAction.setClimateMultipleAnalysedLegends,
          payload: undefined
        });
        dispatch({
          type: AppAction.setClimateMultipleAnalysisResult,
          payload: undefined
        });
        dispatch({
          type: AppAction.setClimateMultipleAnalysisStatus,
          payload: undefined
        });
      }
      dispatch({
        type: AppAction.setMultipleLocationsSetId,
        payload: context.currentMultipleLocations!.id
      });
      let params: AnalysisProps = {
        catNetLayers: [],
        responseStructure: [],
        climateRiskScores: [],
        locations: []
      };
      if (selectedButtons[0].length > 0) {
        params.catNetLayers = selectedButtons[0];
        dispatch({
          type: AppAction.setHazardMultipleAnalysedLegends,
          payload: context.hazardLegends?.filter((item) => {
            return selectedButtons[0].includes(item.layerId);
          })
        });
      }

      if (selectedButtons[1].length > 0) {
        params.climateRiskScores = selectedButtons[1].map((item: any) => {
          return { index: item };
        });
        params.responseStructure = [
          "{2030,2035,2040,2045,2050,2055,2060,2070,2085}"
        ];
        dispatch({
          type: AppAction.setClimateMultipleAnalysedLegends,
          payload: context.crsLayerList?.filter((item) => {
            return selectedButtons[1].includes(item.id);
          })
        });
      }

      context.currentMultipleLocations!.items!.forEach((item) => {
        if (item.checked) {
          params.locations!.push({
            id: item.id,
            coordinates: {
              latitude: item.latitudeWgs,
              longitude: item.longitudeWgs
            }
          });
        }
      });

      if (
        !context.multipleAnalysedLocations ||
        JSON.stringify(params.locations) !==
          JSON.stringify(context.multipleAnalysedLocations)
      ) {
        dispatch({
          type: AppAction.setMultipleAnalysedLocations,
          payload: params.locations
        });
        dispatch({
          type: AppAction.setHazardMultipleAnalysisResult,
          payload: undefined
        });
        dispatch({
          type: AppAction.setClimateMultipleAnalysisResult,
          payload: undefined
        });
      } else {
        params.catNetLayers = params.catNetLayers?.filter((item) => {
          let isFind = context.hazardMultipleAnalysisResult?.find((result) => {
            return result.layerId === item;
          });
          return !isFind;
        });
        params.climateRiskScores = params.climateRiskScores?.filter((item) => {
          if (
            context.climateMultipleAnalysisResult &&
            context.climateMultipleAnalysisResult[0] &&
            context.climateMultipleAnalysisResult[0].climateRiskScores
          ) {
            let isFind =
              context.climateMultipleAnalysisResult[0].climateRiskScores.find(
                (result) => {
                  return result.index === item.index;
                }
              );
            return !isFind;
          } else {
            return true;
          }
        });
      }

      if (params.catNetLayers!.length > 0) {
        dispatch({
          type: AppAction.setHazardMultipleAnalysisStatus,
          payload: "running"
        });
        dispatch({ type: AppAction.setJobId, payload: undefined });
      } else {
        dispatch({
          type: AppAction.setHazardMultipleAnalysisStatus,
          payload: undefined
        });
      }

      if (params.climateRiskScores!.length > 0) {
        dispatch({
          type: AppAction.setClimateMultipleAnalysisStatus,
          payload: "running"
        });
        dispatch({ type: AppAction.setJobId, payload: undefined });
      } else {
        dispatch({
          type: AppAction.setClimateMultipleAnalysisStatus,
          payload: undefined
        });
      }

      onClose();
      dispatch({ type: AppAction.setSingleLocationAnalysed, payload: false });
      dispatch({ type: AppAction.setMultipleLocationsAnalysed, payload: true });
      if (
        params.catNetLayers!.length > 0 ||
        params.climateRiskScores!.length > 0
      ) {
        let res: any = await startMultipleAnalysis(params);
        if (res.code && res.code === 400) {
          dispatch({
            type: AppAction.setSnackBarParams,
            payload: {
              title: "tip",
              titleIconColor: "red",
              titleIcon: "error",
              resMessage:
                getLanguage() === "en" ? res.data.messageEN : res.data.messageCN
            }
          });

          dispatch({
            type: AppAction.setSingleLocationAnalysed,
            payload: false
          });
          dispatch({
            type: AppAction.setMultipleLocationsAnalysed,
            payload: false
          });
          return;
        }
        if (res && res.code === 200) {
          dispatch({
            type: AppAction.setJobId,
            payload: res && res.data && res.data.jobId
          });
        } else {
          dispatch({ type: AppAction.setJobId, payload: undefined });
          if (params.catNetLayers!.length > 0) {
            dispatch({
              type: AppAction.setHazardMultipleAnalysisStatus,
              payload: "error"
            });
          }
          if (params.climateRiskScores!.length > 0) {
            dispatch({
              type: AppAction.setClimateMultipleAnalysisStatus,
              payload: "error"
            });
          }
        }
      }
    }
  };

  return (
    <Dialog maxWidth="xs" open={!!context.fathomPopup} onClose={onClose}>
      <DialogTitle className={styles.title}>
        {t("fathom.popup.title")}
      </DialogTitle>

      <DialogContent>
        <DialogContentText className={styles.content}>
          {t("fathom.popup.text")}
        </DialogContentText>

        {selectedButtons &&
          !!selectedButtons.length &&
          selectedButtons[0] &&
          !!selectedButtons[0].length &&
          selectedButtons[0]
            .filter((e: string) => FATHOM_LAYER_ARRAY.includes(e))
            .map((e: string) => {
              return (
                <div key={e} className={styles.item}>
                  <div>{Svg({ icon: e })()}</div>
                  <div style={{ marginLeft: "6px" }}>{t("layerId." + e)}</div>
                  <FathomIcon className={styles.premium} />
                </div>
              );
            })}

        <div>
          <Checkbox color="success"
            onChange={() => setDonotAskAgain(!donotAskAgain)}
            checked={donotAskAgain}
          />
          {t("fathom.popup.ask")}
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          color="success"
          variant="outlined"
          onClick={() => onClose()}
          style={{ margin: "0 10px 10px 0" }}
        >
          {t("buttons.no")}
        </Button>
        <Button
          color="success"
          variant="contained"
          onClick={() => runAnalysis()}
          style={{ margin: "0 10px 10px 0" }}
        >
          {t("buttons.yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
