import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Defended from "./defended/Defended";
import styles from "./Collapse.module.scss";

interface Props {
  defended: any;
  undefended: any;
  handleCollapse: () => void;
}

export default function Collapse({
  defended,
  undefended,
  handleCollapse
}: Readonly<Props>) {
  const [t] = useTranslation();

  return (
    <div>
      {/* <div>
        Flood depth values (in m) for the lowest relevant return period, up to
        the highest return period... <a>Read more</a>
      </div>

      <div>Source: Global_Fathom_Flood_Zones.pdf</div> */}

      {!!undefended &&
        !!undefended.length &&
        <Accordion
          sx={{
            boxShadow: "none",
            backgroundColor: "transparent",
            margin: "0!important",
            "&::before": {
              display: "none"
            },
            borderBottom: "1px solid #ccc",
            borderRadius: "0!important"
          }}
        >
          <AccordionSummary
            className={styles.summary}
            expandIcon={
              <div className="material-icons">keyboard_arrow_right</div>
            }
            sx={{
              flexDirection: "row-reverse",
              "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                transform: "rotate(90deg)"
              },
              padding: "0 0",
              minHeight: "34px!important",
              height: "36px!important"
            }}
          >
            {t("fathom.Undefended")}
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: "0 0",
              width: "100%",
              borderTop: "1px solid #ccc"
            }}
          >
            <Defended data={undefended} />
          </AccordionDetails>
        </Accordion>}

      {!!defended &&
        !!defended.length &&
        <Accordion
          sx={{
            boxShadow: "none",
            backgroundColor: "transparent",
            margin: "0!important",
            "&::before": {
              display: "none"
            },
            borderBottom: "1px solid #ccc",
            borderRadius: "0!important"
          }}
        >
          <AccordionSummary
            className={styles.summary}
            expandIcon={
              <div className="material-icons">keyboard_arrow_right</div>
            }
            sx={{
              flexDirection: "row-reverse",
              "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                transform: "rotate(90deg)"
              },
              padding: "0 0",
              minHeight: "34px!important",
              height: "36px!important"
            }}
          >
            {t("fathom.Defended")}
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: "0 0",
              width: "100%",
              borderTop: "1px solid #ccc"
            }}
          >
            <Defended data={defended} />
          </AccordionDetails>
        </Accordion>}

      <Button
        variant="text"
        onClick={handleCollapse}
        color="success"
        className={styles.btn}
      >
        {t("buttons.collapse")}
      </Button>
    </div>
  );
}
