import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { getLocationsetsItems } from "api/layer";
import { ReactComponent as MapPinSvg } from "assets/icon_map_pin.svg";
import { ReactComponent as CreateIcon } from "assets/location_set_creat.svg";
import { getLanguage, initTranslations } from "helpers/translations";
import { ReactComponent as ImportIcon } from "assets/location_set_import.svg";
import Icon from "components/icon/icon";
import { LeftPanel, RightPanel } from "helpers/constants";
import { AppAction, AppContext, setLeftPanel } from "helpers/context";
import { getCheckedCounts } from "helpers/helpers";
import { useContext, useState, useEffect } from "react";
import { MapContext } from "helpers/map";
import { useTranslation } from "react-i18next";
import styles from "./data-sets.module.scss";
import { LocationData } from "../../../helpers/models";
import * as turf from '@turf/turf';

interface Props {
	searchText: string;
}

function DataSets(props: Props) {
	const [context, dispatch] = useContext(AppContext);
	const { AMap, map } = useContext(MapContext);
	const [isLoading, setIsLoading] = useState(undefined);
	const [t] = useTranslation();
	const [data, setData] = useState<LocationData[]>(context.multipleLocations || []);
	const [active, setActive] = useState<"name" | "createTime" | "itemCount">("createTime");
	const [direction, setDirection] = useState<{ name: "asc" | "desc"; createTime: "asc" | "desc"; itemCount: "asc" | "desc" }>({ name: "asc", createTime: "asc", itemCount: "asc" });

	const handleSort = (property: "name" | "createTime" | "itemCount") => {
		if (!data) {
			return;
		}

		if (!property) {
			return;
		}

		setActive(property);
		direction[property] = direction[property] === "asc" ? "desc" : "asc";
		setDirection(direction);

		let arr = [...context.multipleLocations!];
		arr.sort((a: LocationData, b: LocationData) => {
			if (!a || !b) {
				return 0;
			}

			const aValue = a?.[property];
			const bValue = b?.[property];

			if (aValue === undefined) {
				return direction[property] === "asc" ? -1 : 1;
			}
			if (bValue === undefined) {
				return direction[property] === "asc" ? 1 : -1;
			}
			if (aValue < bValue) {
				return direction[property] === "asc" ? 1 : -1;
			}
			if (aValue > bValue) {
				return direction[property] === "asc" ? -1 : 1;
			}

			return 0;
		});

		setData([...arr]);
	};

	const getItems = async (locationSet: any, is: boolean = false) => {
		setIsLoading(locationSet.id);

		dispatch({ type: AppAction.setCurrentMultipleLocations, payload: locationSet });
		is && dispatch({ type: AppAction.setMultipleLocationsSetLoadings, payload: true });
		is && dispatch({ type: AppAction.setMultipleLocationsEdit, payload: true });
		let res = await getLocationsetsItems(locationSet.id);
		let multipleLocations = context.multipleLocations!.map((location: any) => {
			if (location.id === locationSet.id) {
				location.items = res.data.items.map((item: any) => {
					item.checked = true;
					return item;
				});
			}
			return location;
		});
		dispatch({ type: AppAction.setCurrentMultipleLocations, payload: res.data });
		setIsLoading(undefined);
		dispatch({ type: AppAction.setMultipleLocations, payload: multipleLocations });

		// if (res.data.items.length > 0) {
		dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Multiple });
		is && dispatch({ type: AppAction.setMultipleLocationsSetLoadings, payload: false });
		handleClickMyLocation(res.data)
		// } else {
		// 	dispatch({
		// 		type: AppAction.setLeftPanel,
		// 		payload: LeftPanel.AddLocation,
		// 	});
		// }
	};

	const openDataPoints = async (locationSet: any) => {
		if (context.multipleLocationsSetId && context.multipleLocationsSetId === locationSet.id) {
			dispatch({ type: AppAction.setMultipleLocationsAnalysed, payload: true });
		} else if (context.multipleLocationsSetId) {
			dispatch({ type: AppAction.setMultipleLocationsAnalysed, payload: false });
		}
		if (locationSet.items === null || locationSet.items.length === 0) {
			await getItems(locationSet, true);
		} else {
			dispatch({ type: AppAction.setCurrentMultipleLocations, payload: locationSet });
			dispatch({ type: AppAction.setMultipleLocationsEdit, payload: true });
			handleClickMyLocation(locationSet)
		}

	};

	const handleClickMyLocation = (locationSet: any) => {
		if (locationSet && locationSet.items) {
			let bounds: any = [];
			locationSet.items.map((item: any) => {
				if (item.checked) {
					bounds.push([item.longitude, item.latitude]);
				}
			});
			if (bounds.length === 0) return
			let bbox: any = turf.bboxPolygon(turf.bbox(turf.multiPoint(bounds)));
			var myBounds = new AMap.Bounds([bbox.bbox[2], bbox.bbox[3]], [bbox.bbox[0], bbox.bbox[1]]);
			console.log(myBounds);
			map?.setBounds(myBounds, true, [20, 20, 20, 20]);
		}
	}

	const handleChangeLocationSet = async (locationSet: any) => {
		if (locationSet.id === context.currentMultipleLocations?.id) {
			dispatch({ type: AppAction.setCurrentMultipleLocations, payload: undefined });
			dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Empty });
		} else {
			if (context.multipleLocationsSetId && context.multipleLocationsSetId === locationSet.id) {
				dispatch({ type: AppAction.setMultipleLocationsAnalysed, payload: true });
			} else if (context.multipleLocationsSetId) {
				dispatch({ type: AppAction.setMultipleLocationsAnalysed, payload: false });
			}
			if (locationSet.items === null || locationSet.items.length === 0) {
				await getItems(locationSet);
			} else {
				dispatch({ type: AppAction.setCurrentMultipleLocations, payload: locationSet });
				handleClickMyLocation(locationSet)
			}
			dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Multiple });
		}
	};

	const importLocations = (): void => {
		dispatch({ type: AppAction.setImportMenu, payload: true });
	};

	const createSet = (): void => {
		dispatch({ type: AppAction.setCreateModal, payload: true });
	};

	const gotoPatchLocationSet = (locationSet: any): void => {
		dispatch({ type: AppAction.setCurrentMultipleLocations, payload: locationSet });
		dispatch({
			type: AppAction.setLeftPanel,
			payload: LeftPanel.EditLocationSet,
		});
	};

	const deleteSetModel = (locationSet: any): void => {
		dispatch({
			type: AppAction.setDeleteLocationSetModal,
			payload: locationSet,
		});
	};

	useEffect(() => {
		let arr = [...context.multipleLocations!];
		arr.sort((a: LocationData, b: LocationData) => {
			if (!a || !b) {
				return 0;
			}

			const aValue = a?.[active];
			const bValue = b?.[active];

			if (aValue === undefined) {
				return direction[active] === "asc" ? -1 : 1;
			}
			if (bValue === undefined) {
				return direction[active] === "asc" ? 1 : -1;
			}

			if (aValue < bValue) {
				return direction[active] === "asc" ? 1 : -1;
			}
			if (aValue > bValue) {
				return direction[active] === "asc" ? -1 : 1;
			}

			return 0;
		});

		setData([...arr]);
	}, [context.multipleLocations]);

	return (
		<div className={styles.sets}>
			<Paper
				sx={{ width: "100%", overflow: "hidden", height: "556px", position: "relative", boxShadow: "none", flex: 1 }}
				className={styles.table}
			>
				<TableContainer sx={{ height: "100%", paddingBottom: "56px" }}>
					<RadioGroup>
						<Table
							stickyHeader
							aria-label="sticky  table"
							size="small"
						>
							<TableHead>
								<TableRow className={styles.row}>
									<TableCell
										align="center"
										style={{ width: 45 }}
									>
										&nbsp;
									</TableCell>
									<TableCell
										align="center"
										style={{ minWidth: 65 }}
									>
										{t("locations.table.type")}
									</TableCell>
									<TableCell
										align="left"
										style={{ minWidth: getLanguage() === "en" ? 130 : 170 }}
									>
										<TableSortLabel
											active={active === "name"}
											onClick={() => handleSort("name")}
											direction={direction.name}
										>
											{t("locations.table.name")}
										</TableSortLabel>
									</TableCell>
									<TableCell
										align="center"
										style={{ minWidth: 120 }}
									>
										<TableSortLabel
											active={active === "itemCount"}
											onClick={() => handleSort("itemCount")}
											direction={direction.itemCount}
										>
											{t("locations.table.locations")}
											{t("locations.table.selected")}
										</TableSortLabel>
									</TableCell>
									<TableCell
										align="center"
										style={{ minWidth: 150 }}
									>
										<TableSortLabel
											active={active === "createTime"}
											onClick={() => handleSort("createTime")}
											direction={direction.createTime}
										>
											{t("locations.table.date")}
										</TableSortLabel>
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{data &&
									data.length > 0 &&
									data.map(
										(locationSet, index) =>
											(props.searchText === "" || locationSet.name.toLowerCase().includes(props.searchText.toLowerCase())) && (
												<TableRow
													key={index}
													selected={context.currentMultipleLocations && context.currentMultipleLocations.id === locationSet.id}
													onClick={() => openDataPoints(locationSet)}
													className={styles.row}
													sx={{
														"&.Mui-selected,&.Mui-selected:hover": {
															backgroundColor: "rgba(14, 138, 63, 0.08)!important",
														},
														"&.Mui-selected .delete-set": {
															background: "linear-gradient(90deg, rgba(238, 238, 238, 0) 0, #ecf6f0 24px)!important",
														},
													}}
												>
													<TableCell
														style={{ padding: 0 }}
														align="center"
													>
														{isLoading === locationSet.id ? (
															<div className="p-[9px] w-[40px] h-[40px]">
																<CircularProgress
																	color="success"
																	size={20}
																/>
															</div>
														) : (
															<Radio
																checked={context.currentMultipleLocations ? context.currentMultipleLocations.id === locationSet.id : false}
																value={locationSet.id}
																color="success"
																onClick={(evt) => {
																	evt.stopPropagation();
																	handleChangeLocationSet(locationSet);
																}}
															/>
														)}
													</TableCell>
													<TableCell align="center">
														<MapPinSvg className={styles.icon} />
													</TableCell>
													<TableCell align="left">{locationSet.name}</TableCell>
													{/* <TableCell 
                                                    align="right">0/{locationSet.itemCount}</TableCell> */}
													<TableCell
														align="right"
														style={{ paddingRight: "30px" }}
													>
														{getCheckedCounts(locationSet.items)}/{locationSet.itemCount}
													</TableCell>
													<TableCell align="center">{locationSet.createTime}</TableCell>
													<TableCell style={{ padding: 0 }}>
														<div className={styles.delete}>
															<div
																className={`${styles.icons} delete-set`}
																onClick={(evt) => evt.stopPropagation()}
															>
																<div
																	className="tooltip tooltip-bottom"
																	data-tip={t("tooltip.edit")}
																>
																	<Icon
																		icon="create"
																		onClick={() => gotoPatchLocationSet(locationSet)}
																	/>
																</div>

																<div
																	className="tooltip tooltip-bottom"
																	data-tip={t("tooltip.delete")}
																>
																	<Icon
																		icon="delete"
																		// onClick={() => deleteSet(locationSet, index)}
																		onClick={() => deleteSetModel(locationSet)}
																	/>
																</div>
															</div>
														</div>
													</TableCell>
												</TableRow>
											)
									)}
							</TableBody>
						</Table>
					</RadioGroup>
					<div
						className={styles.pagination}
						style={{ bottom: context.locationsSize ? "0" : "" }}
					>
						{t("locations.table.pagination")}
						{context.multipleLocations?.length} {t("locations.table.of")} {context.multipleLocations?.length}
					</div>
				</TableContainer>
			</Paper>
			<div className={styles.footer}>
				<Stack
					direction="row"
					spacing={2}
				>
					<Button
						onClick={importLocations}
						variant="contained"
						color="success"
						style={{ width: "206px" }}
						endIcon={<ImportIcon style={{ width: "18px", height: "18px", fill: "white" }} />}
					>
						{t("buttons.import")}
					</Button>
					<Button
						onClick={createSet}
						variant="outlined"
						color="success"
						style={{ width: "150px" }}
						endIcon={<CreateIcon style={{ width: "18px", height: "18px", fill: "green" }} />}
					>
						{t("buttons.create")}
					</Button>
				</Stack>
			</div>
		</div>
	);
}

export default DataSets;
