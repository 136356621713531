
import Menu from "@mui/material/Menu";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from "@mui/material/IconButton";
import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AppContext, AppAction } from "helpers/context";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/en"; // 导入中文语言包
import "dayjs/locale/zh"; // 导入中文语言包


interface FilterColumnsProps {
    anchorEl: any;
    handleClose: any;
    handleApply: any;
    generalList: any[];
    addressList: any[];
}

function FilterEvents(props: FilterColumnsProps) {
    const [t] = useTranslation();
    const [context, dispatch] = useContext(AppContext);
    const [times, setTimes] = useState<any>(null);
    const [expanded, setExpanded] = useState(false);
    const [addressExpanded, setAddressExpanded] = useState(false);
    const open = Boolean(props.anchorEl);
    const [generalData, setGeneralData] = useState<any[]>(JSON.parse(JSON.stringify(props.generalList)));
    const [generalDataCount, setGeneralDataCount] = useState(props.generalList.filter((item) => item.show).length);
    const [addressData, setAddressData] = useState<any[]>(JSON.parse(JSON.stringify(props.addressList)));
    const [addressDataCount, setAddressDataCount] = useState(props.addressList.filter((item) => item.show).length);

    const handleGeneralDataSelect = (index: number) => {
        generalData[index].show = !generalData[index].show
        setGeneralDataCount(generalDataCount + (generalData[index].show ? 1 : -1));
        setGeneralData(generalData);
    }

    const handleGeneralDataSelectAll = (event: any) => {
        let isShowLength = generalData.filter((item) => item.show).length;
        if (generalData.length === isShowLength) {
            setGeneralData(generalData.map((item) => { item.show = false; return item; }));
            setGeneralDataCount(0);
        } else {
            setGeneralData(generalData.map((item) => { item.show = true; return item; }));
            setGeneralDataCount(generalData.length);
        }
    }

    const handleAddressDataSelect = (index: number) => {
        addressData[index].show = !addressData[index].show
        setAddressDataCount(addressDataCount + (addressData[index].show ? 1 : -1));
        setAddressData(addressData);
    }

    const handleAddressDataSelectAll = (event: any) => {
        let isShowLength = addressData.filter((item) => item.show).length;
        if (addressData.length === isShowLength) {
            setAddressData(addressData.map((item) => { item.show = false; return item; }));
            setAddressDataCount(0);
        } else {
            setAddressData(addressData.map((item) => { item.show = true; return item; }));
            setAddressDataCount(addressData.length);
        }
    }

    return <Menu
        anchorEl={props.anchorEl}
        open={open}
        onClose={props.handleClose}
        sx={{
            "& .MuiList-root": {
                py: 0,
            },
        }}
    >
        <div className="card compact dropdown-content p-0 shadow w-[380px] rounded-[4px] overflow-hidden">
            <div className="text-[16px] m-[16px] h-[19px] font-medium text-[#000000de]">{t("events.filterEvents")}</div>
            <div className="p-[16px]">
                <div>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={context.language === "cn" ? "zh" : "en"}

                    >
                        <DatePicker
                            label={t("userSystem.startDate")}
                            value={times}
                            format="YYYY-MM-DD"
                            onChange={(event) => { setTimes(event) }}
                            sx={{
                                width: "100%",
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: "green",
                                },
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "green",
                                },
                            }}
                        />
                    </LocalizationProvider>
                    <FormGroup>
                        <FormControlLabel
                            sx={{
                                "& .MuiFormControlLabel-label": {
                                    fontSize: "14px",
                                    color: "#000000de"
                                }
                            }}
                            control={<Checkbox defaultChecked color="success" />}
                            label="Show selected major events from 1876"
                        />
                    </FormGroup>
                </div>
                <div className="my-[24px] ">
                    <p className="mb-[4px] mt-[0px] text-[13px]">{t("events.type")}</p>
                    <Accordion
                        expanded={expanded}
                        sx={{
                            boxShadow: "none",
                            backgroundColor: "transparent",
                            margin: "0!important",
                            '&::before': {
                                display: 'none',
                            }
                        }}>
                        <AccordionSummary
                            expandIcon={<IconButton onClick={() => setExpanded(expanded ? false : true)}>
                                <div className="material-icons">keyboard_arrow_right</div>
                            </IconButton>}
                            sx={{
                                padding: 0,
                                margin: "0!important",
                                minHeight: "34px!important",
                                height: "36px!important",
                                flexDirection: 'row-reverse',
                                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                    transform: 'rotate(90deg)',
                                },
                                '& .MuiAccordionSummary-content': {
                                    margin: "0!important",
                                },
                                '& .MuiTypography-root': {
                                    fontSize: "14px!important",
                                },
                                '&.Mui-expanded,': {
                                    minHeight: "34px"
                                },
                                '& .MuiAccordionSummary-content.Mui-expanded': {
                                    margin: "0!important",
                                }
                            }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="success"
                                        indeterminate={generalDataCount > 0 && generalData.length !== generalDataCount}
                                        checked={generalDataCount === generalData.length}
                                        onChange={handleGeneralDataSelectAll}
                                        sx={{
                                            width: "36px",
                                            height: "36px",
                                            '& .MuiSvgIcon-root': {
                                                width: "22px!important",
                                                height: "22px!important",
                                            }
                                        }}
                                    />}
                                label={t("locationSet.general")}
                            />

                        </AccordionSummary>
                        <AccordionDetails sx={{
                            padding: "0 0 0 48px",
                            width: "100%",
                            '& .MuiTypography-root': {
                                fontSize: "14px!important",
                            },
                        }}>
                            <FormGroup>
                                {generalData.map((item: any, index: number) => (
                                    <FormControlLabel
                                        control={<Checkbox
                                            color="success"
                                            checked={item.show}
                                            onChange={() => { handleGeneralDataSelect(index) }}
                                            sx={{
                                                width: "36px",
                                                height: "36px",
                                                '& .MuiSvgIcon-root': {
                                                    width: "22px!important",
                                                    height: "22px!important",
                                                }
                                            }}

                                        />}
                                        label={t("locations.add." + item.title)}
                                        key={item.title}
                                    />
                                ))}
                            </FormGroup>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={addressExpanded}
                        sx={{
                            boxShadow: "none",
                            backgroundColor: "transparent",
                            margin: "0!important",
                            '&::before': {
                                display: 'none',
                            }
                        }}>
                        <AccordionDetails sx={{
                            padding: "0 0 0 40px",
                            width: "100%",
                            '& .MuiTypography-root': {
                                fontSize: "14px!important",
                            },
                        }}>
                            <FormGroup>
                                {addressData.map((item: any, index: number) => (
                                    <FormControlLabel
                                        control={<Checkbox
                                            color="success"
                                            checked={item.show}
                                            onChange={() => { handleAddressDataSelect(index) }}
                                            sx={{
                                                width: "36px",
                                                height: "36px",
                                                '& .MuiSvgIcon-root': {
                                                    width: "22px!important",
                                                    height: "22px!important",
                                                }
                                            }}

                                        />}
                                        label={t("locations.add." + item.title)}
                                        key={item.title}
                                    />
                                ))}
                            </FormGroup>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
            <div
                style={{
                    borderTop: '1px solid var(--gray-border)',
                    padding: '16px',
                    height: '64px',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Button
                    color="success"
                    disabled={generalDataCount === generalData.length && addressDataCount === 0}
                    onClick={() => {
                        setGeneralData(generalData.map((item: any) => ({ ...item, show: true })));
                        setAddressData(addressData.map((item: any) => ({ ...item, show: false })));
                        setGeneralDataCount(generalData.length);
                        setAddressDataCount(0);
                    }}
                >
                    {t("buttons.reset")}
                </Button>
                <div>

                    <Button
                        color="success"
                        style={{ marginRight: '10px' }}
                        onClick={props.handleClose}>
                        {t("buttons.close")}
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        disabled={JSON.stringify(generalData) === JSON.stringify(props.generalList) && JSON.stringify(addressData) === JSON.stringify(props.addressList)}
                        onClick={() => props.handleApply(generalData, addressData)}
                    >
                        {t("buttons.apply")}
                    </Button>
                </div>
            </div>
        </div>
    </Menu>;
}
export default FilterEvents;