import Modal from "components/modal/modal";

import Button from "components/button/button";
import { AppAction, AppContext } from "helpers/context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./info-modal.module.scss";

function InfoModal() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();

	const title = (): string => {
		if (context.tipModal) {
			return context.tipModal.name;
		}
		return "";
	};

	const text = (): string => {
		if (context.tipModal) {
			return context.tipModal.description;
		}
		return "";
	};

	const close = (): void => {
		dispatch({
			type: AppAction.setTipModal,
			payload: undefined,
		});
	};

	return (
		<Modal
			header={title()}
			opened={context.tipModal ? true : false}
			onClose={close}
		>
			<div className={styles.info}>
				<div className={styles.text}>{text()}</div>
				<div
					className={styles.text}
					style={{ paddingTop: "0" }}
				>
					8.5349604,47.3592290
				</div>
				<div className={styles.button}>
					<Button
						onClick={close}
						style={{ outline: "none" }}
					>
						{t("buttons.close")}
					</Button>
				</div>
			</div>
		</Modal>
	);
}

export default InfoModal;
