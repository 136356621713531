
import Menu from "@mui/material/Menu";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from "@mui/material/IconButton";
import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";

interface FilterColumnsProps {
    anchorEl: any;
    handleClose: any;
    handleApply: any;
    generalList: any[];
    addressList: any[];
}

function FilterColumns(props: FilterColumnsProps) {
    const [t] = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const [addressExpanded, setAddressExpanded] = useState(false);
    const open = Boolean(props.anchorEl);
    const [generalData, setGeneralData] = useState<any[]>(JSON.parse(JSON.stringify(props.generalList)));
    const [generalDataCount, setGeneralDataCount] = useState(props.generalList.filter((item) => item.show).length);
    const [addressData, setAddressData] = useState<any[]>(JSON.parse(JSON.stringify(props.addressList)));
    const [addressDataCount, setAddressDataCount] = useState(props.addressList.filter((item) => item.show).length);

    const handleGeneralDataSelect = (index: number) => {
        generalData[index].show = !generalData[index].show
        setGeneralDataCount(generalDataCount + (generalData[index].show ? 1 : -1));
        setGeneralData(generalData);
    }

    const handleGeneralDataSelectAll = (event: any) => {
        let isShowLength = generalData.filter((item) => item.show).length;
        if (generalData.length === isShowLength) {
            setGeneralData(generalData.map((item) => { item.show = false; return item; }));
            setGeneralDataCount(0);
        } else {
            setGeneralData(generalData.map((item) => { item.show = true; return item; }));
            setGeneralDataCount(generalData.length);
        }
    }

    const handleAddressDataSelect = (index: number) => {
        addressData[index].show = !addressData[index].show
        setAddressDataCount(addressDataCount + (addressData[index].show ? 1 : -1));
        setAddressData(addressData);
    }

    const handleAddressDataSelectAll = (event: any) => {
        let isShowLength = addressData.filter((item) => item.show).length;
        if (addressData.length === isShowLength) {
            setAddressData(addressData.map((item) => { item.show = false; return item; }));
            setAddressDataCount(0);
        } else {
            setAddressData(addressData.map((item) => { item.show = true; return item; }));
            setAddressDataCount(addressData.length);
        }
    }

    return <Menu
        anchorEl={props.anchorEl}
        open={open}
        onClose={props.handleClose}
        sx={{
            "& .MuiList-root": {
                py: 0,
            },
        }}
    >
        <div className="card compact dropdown-content p-0 shadow w-[380px] rounded-[4px] overflow-hidden">
            <div className="text-[16px] m-[16px] h-[19px] font-medium text-[#000000de]">{t("locationSet.columnsTitle")}</div>
            <div className="p-[8px] h-[300px] overflow-y-auto">
                <Accordion
                    expanded={expanded}
                    sx={{
                        boxShadow: "none",
                        backgroundColor: "transparent",
                        margin: "0!important",
                        '&::before': {
                            display: 'none',
                        }
                    }}>
                    <AccordionSummary
                        expandIcon={<IconButton onClick={() => setExpanded(expanded ? false : true)}>
                            <div className="material-icons">keyboard_arrow_right</div>
                        </IconButton>}
                        sx={{
                            padding: 0,
                            margin: "0!important",
                            minHeight: "34px!important",
                            height: "36px!important",
                            flexDirection: 'row-reverse',
                            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                transform: 'rotate(90deg)',
                            },
                            '& .MuiAccordionSummary-content': {
                                margin: "0!important",
                            },
                            '& .MuiTypography-root': {
                                fontSize: "14px!important",
                            },
                            '&.Mui-expanded,': {
                                minHeight: "34px"
                            },
                            '& .MuiAccordionSummary-content.Mui-expanded': {
                                margin: "0!important",
                            }
                        }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="success"
                                    indeterminate={generalDataCount > 0 && generalData.length !== generalDataCount}
                                    checked={generalDataCount === generalData.length}
                                    onChange={handleGeneralDataSelectAll}
                                    sx={{
                                        width: "36px",
                                        height: "36px",
                                        '& .MuiSvgIcon-root': {
                                            width: "22px!important",
                                            height: "22px!important",
                                        }
                                    }}
                                />}
                            label={t("locationSet.general")}
                        />

                    </AccordionSummary>
                    <AccordionDetails sx={{
                        padding: "0 0 0 48px",
                        width: "100%",
                        '& .MuiTypography-root': {
                            fontSize: "14px!important",
                        },
                    }}>
                        <FormGroup>
                            {generalData.map((item: any, index: number) => (
                                <FormControlLabel
                                    control={<Checkbox
                                        color="success"
                                        checked={item.show}
                                        onChange={() => { handleGeneralDataSelect(index) }}
                                        sx={{
                                            width: "36px",
                                            height: "36px",
                                            '& .MuiSvgIcon-root': {
                                                width: "22px!important",
                                                height: "22px!important",
                                            }
                                        }}

                                    />}
                                    label={t("locations.add." + item.title)}
                                    key={item.title}
                                />
                            ))}
                        </FormGroup>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={addressExpanded}
                    sx={{
                        boxShadow: "none",
                        backgroundColor: "transparent",
                        margin: "0!important",
                        '&::before': {
                            display: 'none',
                        }
                    }}>
                    <AccordionSummary
                        expandIcon={<IconButton onClick={() => setAddressExpanded(addressExpanded ? false : true)}>
                            <div className="material-icons">keyboard_arrow_right</div>
                        </IconButton>}
                        sx={{
                            padding: 0,
                            margin: "0!important",
                            minHeight: "34px!important",
                            height: "36px!important",
                            flexDirection: 'row-reverse',
                            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                transform: 'rotate(90deg)',
                            },
                            '& .MuiAccordionSummary-content': {
                                margin: "0!important",
                            },
                            '& .MuiTypography-root': {
                                fontSize: "14px!important",
                            },
                            '&.Mui-expanded,': {
                                minHeight: "34px"
                            },
                            '& .MuiAccordionSummary-content.Mui-expanded': {
                                margin: "0!important",
                            }
                        }}>
                        <FormControlLabel
                            control={<Checkbox
                                color="success"
                                indeterminate={addressDataCount > 0 && addressData.length !== addressDataCount}
                                checked={addressDataCount === addressData.length}
                                onChange={handleAddressDataSelectAll}
                                sx={{
                                    width: "36px",
                                    height: "36px",
                                    '& .MuiSvgIcon-root': {
                                        width: "22px!important",
                                        height: "22px!important",
                                    }
                                }}
                            />}
                            label={t("addressEncoding.table.address")}
                        />

                    </AccordionSummary>
                    <AccordionDetails sx={{
                        padding: "0 0 0 48px",
                        width: "100%",
                        '& .MuiTypography-root': {
                            fontSize: "14px!important",
                        },
                    }}>
                        <FormGroup>
                            {addressData.map((item: any, index: number) => (
                                <FormControlLabel
                                    control={<Checkbox
                                        color="success"
                                        checked={item.show}
                                        onChange={() => { handleAddressDataSelect(index) }}
                                        sx={{
                                            width: "36px",
                                            height: "36px",
                                            '& .MuiSvgIcon-root': {
                                                width: "22px!important",
                                                height: "22px!important",
                                            }
                                        }}

                                    />}
                                    label={t("locations.add." + item.title)}
                                    key={item.title}
                                />
                            ))}
                        </FormGroup>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div
                style={{
                    borderTop: '1px solid var(--gray-border)',
                    padding: '16px',
                    height: '64px',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Button
                    color="success"
                    disabled={generalDataCount === generalData.length && addressDataCount === 0}
                    onClick={() => {
                        setGeneralData(generalData.map((item: any) => ({ ...item, show: true })));
                        setAddressData(addressData.map((item: any) => ({ ...item, show: false })));
                        setGeneralDataCount(generalData.length);
                        setAddressDataCount(0);
                    }}
                >
                    {t("buttons.reset")}
                </Button>
                <div>

                    <Button
                        color="success"
                        style={{ marginRight: '10px' }}
                        onClick={props.handleClose}>
                        {t("buttons.close")}
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        disabled={JSON.stringify(generalData) === JSON.stringify(props.generalList) && JSON.stringify(addressData) === JSON.stringify(props.addressList)}
                        onClick={() => props.handleApply(generalData, addressData)}
                    >
                        {t("buttons.apply")}
                    </Button>
                </div>
            </div>
        </div>
    </Menu>;
}
export default FilterColumns;