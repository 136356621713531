import { logout } from 'api/layer';
import Button from "components/button/button";
import Modal from "components/modal/modal";
import { useTranslation } from "react-i18next";
import styles from "./alertModal.module.scss";
import { useContext, useEffect, useState } from "react";
import { AppAction, AppContext } from "helpers/context";

function AlertModal() {
    const [context, dispatch] = useContext(AppContext);
    const [t] = useTranslation();
    const close = (): void => {
        sessionStorage.clear();
        window.location.href = (window as any).logoutUrl as string;
    };

    return (
        <Modal
            header={t("alertModal.title")}
            opened={(window as any).visibleAlertModal}
        >
            <div className={styles.alertModal}>
                <div className={styles.text}>{t("alertModal.message")}</div>
                <div className={styles.button}>
                    <Button onClick={close}>{t("buttons.confirm")}</Button>
                </div>
            </div>
        </Modal>
    );
}

export default AlertModal;
