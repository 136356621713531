import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./Collapse.module.scss";

interface Props {
  detail: Detail;
  handleCollapse: () => void;
}

interface Detail {
  [key: string]: string;
}

export default function Collapse({ detail, handleCollapse }: Readonly<Props>) {
  const [t] = useTranslation();

  return (
    <div>
      <Stack spacing={0.3} className={styles.detail}>
        {Object.keys(detail).map((e) => {
          return (
            <Stack key={e} direction="row" spacing={2}>
              <div className={styles.key}>
                {t("legend." + e).startsWith("legend.") ? e : t("legend." + e)}
              </div>
              <div className={styles.value}>
                {t("legend." + detail[e]).startsWith("legend.")
                  ? detail[e]
                  : t("legend." + detail[e])}
              </div>
            </Stack>
          );
        })}
      </Stack>
      <Button
        variant="text"
        onClick={handleCollapse}
        color="success"
        className={styles.btn}
      >
        {t("buttons.collapse")}
      </Button>
    </div>
  );
}
